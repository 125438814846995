import {
    useEffect,
    useRef,
    useContext
} from "react";
import './FilterEditor.scss';
import useState from 'react-usestateref';
import classnames from 'classnames';
import { ByzzerCategorySelect } from "@/components/ByzzerCategorySelect";
import { ByzzerBrandSearch } from "@/components/ByzzerBrandSearch";
import ByzzerMarketSearch from "@/components/MarketSelection/ByzzerMarketSearch/ByzzerMarketSearch";
import { TimePeriodPicker } from "@/components/TimePeriodPicker";
import {
    ScenarioFilterRunConfigOptions,
    ScenarioSimulatorConfig,
    SimulatorRunConfig
} from "@/types/SimulatorTypes";
import { ByzzerButton, WizardStepMessageOptions } from "@byzzer/ui-components";
import { MonthEndingTimePeriod, TimePeriod, WeekEndingTimePeriod } from "@/types/ReportRun";
import ByzzerPPGSelect from "@/components/ByzzerPPGSelect/ByzzerPPGSelect";
import { ByzzerPPGGroupPicker } from "@/components/ByzzerPPGGroupPicker";
import { ScenarioSimulatorContext } from "@/views/simulators";
import { ByzzerMask } from "@/components/ByzzerMask/ByzzerMask";
import { MarketRunConfigOptions, ProductRunConfigOptions, TimeRunPeriodConfigOptions } from "@/types/RunConfigOptions";
import { LimitedLabel } from "@/components/LimitedLabel";
import { TrackClick, useEventDataWithUserInfo } from "@/analytics";

export type FilterEditorProps = {
    filterConfigOptions?: ScenarioFilterRunConfigOptions;
    code?: string;
    name?: keyof ScenarioSimulatorConfig;
    onStartSimulation?: () => void;
    onModifyFilters?: () => void;
    onToggle?: () => void;
    message?: WizardStepMessageOptions;
    trackClick: {
        data: {
            sku: string;
            title: string;
        }
    }
}

export const FilterEditor = ({
                                 onStartSimulation,
                                 onModifyFilters,
                                 onToggle,
                                 code,
                                 name,
                                 message,
                                 trackClick,
                                 ...props
                             }: FilterEditorProps) => {

    const baseClassName = 'byz-filter-editor';

    const {
        value: {
            filterEditor: {
                filterConfigOptions,
                filterRunConfig: contextFilterValues
            }
        },
        state,
        onChange: onContextChange
    } = useContext(ScenarioSimulatorContext);

    const getEventData = useEventDataWithUserInfo();

    const productConfigOptions: Partial<Omit<ProductRunConfigOptions, 'type' | 'title'>> = filterConfigOptions.find((configOptionSet) => configOptionSet.type === 'product') ?? {};
    const marketConfigOptions: Partial<Omit<MarketRunConfigOptions, 'title' | 'type'>> = filterConfigOptions.find((configOptionSet) => configOptionSet.type === 'market') ?? {};
    const timeConfigOptions: Partial<Omit<TimeRunPeriodConfigOptions, 'title' | 'type'>> = filterConfigOptions.find((configOptionSet) => configOptionSet.type === 'time_period') ?? {};

    const [internalValue, setInternalValue, internalValueRef] = useState<SimulatorRunConfig>();

    const [busy, setBusy] = useState<boolean>(false);
    const [selectionsAreValid, setSelectionsAreValid, selectionsAreValidRef] = useState<boolean>(false);
    const wasValid = useRef<boolean>(false);

    const {
        includeBrands, requireBrands,
        includeCategories, requireCategories,
        includePPGDefinition, requirePPGDefinition,
        includePPGs, requirePPGs
    } = productConfigOptions;

    const isRms = marketConfigOptions.datatype === 'rms';
    const isCps = marketConfigOptions.datatype === 'cps';

    const showMarketTree = (isCps || isRms);
    const showMarketSearch = showMarketTree;

    useEffect(() => {
        if (internalValue) {
            const isValid = checkIfValidSelections(internalValue);

            setSelectionsAreValid(curr => isValid)

            if (wasValid.current !== isValid) {
                wasValid.current = isValid;
            }
        }
    }, [internalValue]);

    useEffect(() => {
        if (selectionsAreValid !== undefined) {
            // console.log(`FilterEditor - useEffect / 'selectionsAreValid' updated ===>> `, selectionsAreValid);
        }
    }, [selectionsAreValid]);

    function checkIfValidSelections(internalValue: SimulatorRunConfig): boolean {
        const newValidityResult = Boolean(
            (!requireBrands || internalValue?.brands?.length) &&
            (!requireCategories || internalValue?.categories?.length) &&
            (!requirePPGDefinition || internalValue?.ppgGroupNames?.length) &&
            (!requirePPGs || internalValue?.ppgId) &&
            Boolean(internalValue?.markets?.length) &&
            Boolean(validateTimePeriodSelection(internalValue?.timePeriod!))
        );

        return newValidityResult;
    }

    function validateTimePeriodSelection(timePeriod: TimePeriod) { // todo: this function exists in TimePeriodRunConfigFilters.  DRY this
        let isValid = false;

        if (['omni', 'relative'].includes(timePeriod?.type!)) {
            isValid = Boolean(timePeriod?.period);
        } else if (['week_ending', 'omni_custom'].includes(timePeriod?.type!)) {

            if (timePeriod?.type === 'week_ending') {
                const tp = (timePeriod as WeekEndingTimePeriod);
                isValid = Boolean(tp.period && tp.period.weeks! > 0 && tp.period.endDate);
            }
            if (timePeriod?.type === 'omni_custom') {
                const tp = (timePeriod as MonthEndingTimePeriod);
                isValid = Boolean(tp.period && tp.period.months! > 0 && tp.period.endDate);
            }
        }

        return isValid;
    }

    useEffect(() => {
        if (contextFilterValues) {
            setInternalValue(contextFilterValues);
        }
    }, [contextFilterValues]);

    function handleChange(e: ByzzerChangeEvent<unknown>) {
        let newValues = {
            ...internalValueRef.current,
            [e.name!]: e.value,
        }

        if (['categories'].includes(e.name!)) {
            newValues = {
                ...newValues,
                brands: [],
                ppgId: null,
                ppgGroupNames: [],
                markets: []
            }
        }
        if (['brands', 'ppgId'].includes(e.name!)) {
            newValues = {
                ...newValues,
                ppgGroupNames: []
            }
        }

        if (onContextChange) {
            onContextChange(
                name!,
                newValues,
                e?.data
            )
        } else {
            setInternalValue(newValues)
        }
    }

    const handleModifyFilters = async () => { // TODO: Will this function provide all of the selections from this component up to the parent?
        if (!internalValue) return;
        onModifyFilters?.();
    }

    const handleStartSimulation = async () => { // TODO: Will this function provide all of the selections from this component up to the parent?
        if (!internalValue) return;
        onStartSimulation?.();
    }

    return (
        <div className={classnames(`${baseClassName}`)}>
            <ByzzerMask loading={busy}>Loading your PPGs...</ByzzerMask>
            <header className={classnames(`${baseClassName}__header`)}>
                {message?.content ? (
                    <div className={classnames(`${baseClassName}__message-box`, {
                        [`${baseClassName}__message-box--error`]: message?.type === 'error',
                        // [`${baseClassName}__message-box--warning`]: message?.type === 'warning', // we may want to reuse later
                    })}>
                        {message?.content} {/* // todo: DRY - consolidate code to use same components as reports */}
                    </div>
                ) : (
                    <p>What is the scope of your scenario?</p>
                )}
            </header>
            <main className={classnames(`${baseClassName}__selectors`)}>
                <ByzzerCategorySelect
                    className={`${baseClassName}__selector`}
                    name={'categories'}
                    onlyRenderIf={includeCategories}
                    value={internalValue?.categories}
                    onChange={handleChange}
                    placeholder={'Select from the list'}
                    label={'Category(s):'}
                />
                <ByzzerBrandSearch
                    className={`${baseClassName}__selector`}
                    onlyRenderIf={includeBrands}
                    name={'brands'}
                    value={internalValue?.brands}
                    onChange={handleChange}
                    label={
                        <LimitedLabel
                            max={undefined} // todo: Add actual Alby props to min and max
                            min={undefined}
                            required={requireBrands}
                            single={'Brand(s):'}
                            exact={<>Brand(s):</>}
                            limited={<>Brand(s):</>}
                            unlimited={'Brand(s):'}
                            includeSuffix={!requireBrands}
                        />
                    }
                    categories={internalValue?.categories}
                    disabled={!internalValue?.categories?.length}
                />
                <ByzzerPPGSelect
                    className={`${baseClassName}__selector`}
                    name={'ppgId'}
                    onlyRenderIf={includePPGs}
                    label={"PPG Definition:"}
                    onChange={handleChange}
                    value={internalValue?.ppgId}
                    categories={internalValue?.categories}
                    disabled={!internalValue?.categories?.length}
                    allowClear={true}
                    dontUseDefault={true}
                    showDescription={false} // TODO: a temp workaround to hide a description that's built into ppgselect.  ultimately we'll want to move that description up-n-out.  for now just hiding it
                />
                <ByzzerPPGGroupPicker
                    className={`${baseClassName}__selector`}
                    name={"ppgGroupNames"}
                    label={"PPG:"}
                    value={internalValue?.ppgGroupNames}
                    onlyRenderIf={includePPGDefinition}
                    onChange={handleChange}
                    disabled={!internalValue?.categories?.length || !internalValue?.ppgId}
                    allowClear={true}
                    ppgId={internalValue?.ppgId!}
                    maxSelections={1}
                    categories={internalValue?.categories ?? []}
                    brands={internalValue?.brands ?? []}
                    // markets={internalValue?.markets}
                    timePeriod={internalValue?.timePeriod}
                    setBusy={setBusy}
                />
                {showMarketSearch && (
                    <ByzzerMarketSearch
                        className={`${baseClassName}__selector`}
                        name={'markets'}
                        label={Boolean((Number(marketConfigOptions?.maxMarkets) > 1)) ?  'Market(s):' : 'Market:'}
                        reportSku={'57'} // TODO - this is a temporary workaround just to make it work for RMS
                        categories={internalValue?.categories}
                        value={internalValue?.markets}
                        onChange={handleChange}
                        disabled={!internalValue?.categories?.length}
                        maxSelections={marketConfigOptions?.maxMarkets ?? 1}
                        placeholder={"Search for a market"}
                    />
                )}
                {Boolean(internalValue?.timePeriod) && (
                    <div className={`${baseClassName}__ppg-groups`}>
                        <div className={`${baseClassName}__ppg-groups-label`}>
                            Time Period:
                        </div>
                        <div className={`${baseClassName}__time-picker-wrapper`}>
                            <TimePeriodPicker
                                name={'timePeriod'}
                                value={internalValue?.timePeriod}
                                onChange={handleChange}
                                datatype={timeConfigOptions.datatype}
                            />
                        </div>
                    </div>
                )}
            </main>

            <footer className={classnames(`${baseClassName}__start-simulation`)}>
                <TrackClick 
                    name={'simulator_run'}
                    data={getEventData(trackClick.data)}
                >
                    <ByzzerButton
                        onClick={handleStartSimulation}
                        label={state?.filtersEdited === false ? `Return to Simulation` : `Start Simulation`}
                        disabled={!selectionsAreValid || Boolean(message)}
                    />
                </TrackClick>
            </footer>
        </div>
    );

};

export default FilterEditor;

FilterEditor.displayName = 'FilterEditor';