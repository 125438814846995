import './DodLayoutEditor.scss';
import React, { useMemo, useEffect, ReactNode, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import classnames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import Tippy from '@tippyjs/react';
import { format as formatDate } from 'date-fns';
import { ByzzerSelect, ByzzerSelectOptionGroup } from '@byzzer/ui-components';
import DodLayoutOptions from '@/components/DodConfigEditor/builders/DodLayoutBuilder/DodLayoutOptions/DodLayoutOptions';
import DodLayoutConditionBuilder from '@/components/DodConfigEditor/builders/DodLayoutBuilder/DodLayoutConditionBuilder/DodLayoutConditionBuilder';
import DodDesignYourLayout from '@/components/DodConfigEditor/builders/DodLayoutBuilder/DodDesignYourLayout/DodDesignYourLayout';
import { DodPanel } from '@/components/DodConfigEditor/common/DodPanel';
import { DodPresetConfig, RowColConfig } from '@/components/DodConfigEditor/types';
import { DodWizardContextValue, useDodWizard } from '@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext';
import { openDodConditionBuilderModal } from '@/components/DodConfigEditor/builders/DodLayoutBuilder/DodLayoutConditionBuilder/DodConditionBuilderModal';
import { toAxisDef } from '@/components/DodConfigEditor/toAxisDef';
import { confirm, openModal } from '@/components/form';
import {
    QUICK_LAYOUT_OPTIONS,
    quickLayoutAllowedOptions,
} from '@/components/DodConfigEditor/builders/DodLayoutBuilder/DodLayoutEditor/quickLayouts';
import { useApp, useUser } from '@/contexts/UserContext';
import { useUndo } from '@/hooks/useUndo';
import { useEventDataWithUserInfo, useTrackEvent } from '@/analytics/AnalyticsContext';
import { TimePeriodRange } from '@/utils/timePeriod/TimePeriodRange';
import {
    DodAxisDef,
    DodColDef,
    DodLayoutConfig,
    DodLayoutPreset,
    DodRowDef,
    DodRunConfig,
    FactCondition,
    SortType,
} from '@/types/DodRun';
import { openCreateDodPresetModal } from '@/components/DodConfigEditor/common/DodSavedSelection/DodSaveSelectionModal';
import { DodPresetType } from '@/types/ApiTypes';
import { useDodService } from '@/services/dodPresets.service';
import { factSetToDisplayNames } from '@/components/DodConfigEditor/common/utils';
import { difference, intersection, uniq } from 'lodash';
import { alert as byzzerAlert } from '@/components/form';
import { SUB_DIMENSION_VALUE_TO_DISPLAY } from '@/components/DodConfigEditor/builders/DodLayoutBuilder/DodExcelLayoutPreview/DodExcelLayoutPreview';
import { caseInsensitiveReverseSort, caseInsensitiveSort } from '@/utils/Sort';

const baseClassName = 'dod-layout-editor';

export type DodLayoutEditorProps = {
    className?: string;
    name?: string;
    value: DodRunConfig;
    title: string;
    savedSelectionType: DodPresetType;
    savedSelectionDescription: ReactNode;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

export type UndoState = {
    timePeriodsReordered?: boolean;
    conditions?: FactCondition[];
    layout?: DodLayoutConfig;
    rowColConfig?: RowColConfig[];
};

type OrderConfig = {
    products: string[];
    markets: string[];
    time_periods: string[];
    facts: string[];
};

export function DodLayoutEditor({
    className,
    name,
    value,
    title,
    savedSelectionType,
    savedSelectionDescription,
}: DodLayoutEditorProps) {
    const [searchParams] = useSearchParams();
    const { maxDataDates, reloadDodPresets } = useApp();
    const trackEvent = useTrackEvent();
    const getEventData = useEventDataWithUserInfo();
    const { addStateToHistory, undo, canUndo } = useUndo<UndoState>();
    const [saveSelectionModalOpened, setSaveSelectionModalOpened] = useState<boolean>(false);

    const maxDate = useMemo<string>(() => {
        return formatDate(maxDataDates.rms!, 'yyyyMMdd');
    }, [maxDataDates]);

    const {
        runConfig,
        setRunConfig,
        setRowColConfigs,
        applyValues,
        rowColConfigs,
        suppressQuickLayoutWarning,
        setSuppressQuickLayoutWarning,
        timePeriodsReordered,
        setTimePeriodsReordered,
        presets,
        setPreset,
    } = useDodWizard();

    const { dodExcelTemplates, user } = useUser();
    const { dodPresetsMap, deletePreset } = useDodService();

    const layoutId = useMemo<string>(() => {
        if (value.layout.templateId) {
            return `${value.layout.templateId}`;
        } else if (value.layout.savedLayoutId) {
            return `${value.layout.savedLayoutId}`;
        } else if (value.layout.quickLayoutCode) {
            return value.layout.quickLayoutCode;
        }
        return 'cl';
    }, [value.layout]);

    const mode = useMemo<string | null>(() => {
        return searchParams.get('mode');
    }, [searchParams]);

    const disabledQuickLayoutOptions = useMemo(() => {
        if (value.layout.includeSubTotals && mode === 'layout' && value.layout.quickLayoutCode) {
            return QUICK_LAYOUT_OPTIONS.filter(
                (ql) =>
                    value.layout.quickLayoutCode &&
                    !quickLayoutAllowedOptions[value.layout.quickLayoutCode].includes(ql.value)
            );
        }
        return [];
    }, []);

    const layoutOptions = useMemo<ByzzerSelectOptionGroup[]>(() => {
        const excelLayoutTemplates = dodExcelTemplates.map((template) => ({
            display: `Excel Template: ${template.displayName}`,
            value: `${template.id}`,
            data: toDodPresetConfig(template.layout),
        }));
        const savedLayouts = dodPresetsMap.layout.map((layoutPreset) => ({
            display: `Saved Layout: ${layoutPreset.displayName}`,
            value: `${layoutPreset.id}`,
            data: {
                layout: {
                    ...toDodPresetConfig((layoutPreset.values as DodLayoutPreset).layout),
                    includeCategoryTotals: (layoutPreset.values as DodLayoutPreset).layout.includeCategoryTotals,
                    includeSubTotals: (layoutPreset.values as DodLayoutPreset).layout.includeSubTotals,
                    includeExtractDate: (layoutPreset.values as DodLayoutPreset).layout.includeExtractDate,
                },
                conditions: (layoutPreset.values as DodLayoutPreset).conditions,
            },
        }));

        // TODO: add ReactNode | string for the display in ByzzerSelectOptionGroup
        return [
            {
                label: 'Quick Layout',
                options: QUICK_LAYOUT_OPTIONS.map((ql) => {
                    if (disabledQuickLayoutOptions.find((dql) => dql.value === ql.value)) {
                        return {
                            ...ql,
                            display: (
                                <Tippy
                                    content={
                                        'This layout change requires your subtotals to be recalculated. Please modify your run instead of using the apply new layout option.'
                                    }
                                    placement={'bottom'}
                                    delay={[500, 0]}
                                >
                                    <span>{ql.display}</span>
                                </Tippy>
                            ),
                        };
                    }
                    return { ...ql };
                }),
            },
            { label: 'Saved Layouts', options: savedLayouts },
            { label: 'Excel Templates', options: excelLayoutTemplates },
        ];
    }, [dodExcelTemplates, dodPresetsMap]);

    //BYZ-12113 - Hide and stack option should be disabled always if any of the market in run is partially-approved
    const hasPartiallyApprovedMarkets = useMemo(
        () => runConfig.filters.markets.values.some((item) => item.marketHasPartialApproval === true),
        [runConfig.filters.markets.values]
    );

    function getSavedLayoutById(layoutId: number) {
        const savedLayout = dodPresetsMap.layout.find((preset) => Number(preset.id) === Number(layoutId));
        return savedLayout;
    }

    // keeping this block for now, will remove once saved layouts are stable
    
    // useEffect(() => {
        // BYZ-8480 sorting sub dimension values after applying a saved layout
        // after trying out few fixes, below solution is what seemed to work
        // if you feel you have any suggestions, we can brainstorm to come up with a better approach
        // if (runConfig.layout.savedLayoutId) {
            // const { presetOrderConfigs, currentOrderConfigs } = getPresetLayoutOrderAndCurrentLayoutOrder(
            //     runConfig.layout.savedLayoutId
            // );

            // // find what values are common between preset's order and current selection order and put it in a json map
            // const intersectionOrderConfig = {
            //     products: intersection(presetOrderConfigs['products'], currentOrderConfigs['products']),
            //     markets: intersection(presetOrderConfigs['markets'], currentOrderConfigs['markets']),
            //     time_periods: intersection(presetOrderConfigs['time_periods'], currentOrderConfigs['time_periods']),
            //     facts: intersection(presetOrderConfigs['facts'], currentOrderConfigs['facts']),
            // };

            // // find what values present in current selection, but not in presets
            // // we want this because thats what the user has selected to run this dod
            // // we dont care of the values present in preset and not in current selection,
            // // simply because user did not select them for this run
            // const differenceOrderConfig = {
            //     products: difference(currentOrderConfigs['products'], presetOrderConfigs['products']),
            //     markets: difference(currentOrderConfigs['markets'], presetOrderConfigs['markets']),
            //     time_periods: difference(currentOrderConfigs['time_periods'], presetOrderConfigs['time_periods']),
            //     facts: difference(currentOrderConfigs['facts'], presetOrderConfigs['facts']),
            // };

            // // as per requirement discussed for ordering in BYZ-8480, when a saved layout is applied in layout step,
            // // we place the common values first and then append the remaining values from current selection,
            // // which is happening below for all dimensions
            // const resultOrderConfig = {
            //     products: [...intersectionOrderConfig['products'], ...differenceOrderConfig['products']],
            //     markets: [...intersectionOrderConfig['markets'], ...differenceOrderConfig['markets']],
            //     time_periods: [...intersectionOrderConfig['time_periods'], ...differenceOrderConfig['time_periods']],
            //     facts: [...intersectionOrderConfig['facts'], ...differenceOrderConfig['facts']],
            // };

            // // keeping the console log until qa finishes testing
            // console.log('interesection', intersectionOrderConfig);
            // console.log('difference', differenceOrderConfig);
            // console.log('result', resultOrderConfig);

            // finally, in order for the new set of values to reflect on layout tab, this order needs to be propagated to rowColConfigs
            // setRowColConfigs((rowColConfigs) => {
            //     let updatedRowColConfigs: RowColConfig[] = [];

            //     // for markets, timeperiods and facts, its pretty straight-forward, we just need to update the 'values',
            //     // other config like axis, stack, page, type, dim all remains the same
            //     const nonProducts = rowColConfigs
            //         .filter((config) => config.type !== 'products')
            //         .map((config) => ({
            //             ...config,
            //             values: resultOrderConfig[config.type],
            //         }));

            //     // however for products, we search if the dim already exists in previous rowColConfig,
            //     // if present, just return that object and not modify anything, as we want to preserve
            //     // its config like page, stack, hide which user might have selected
            //     const products: RowColConfig[] = resultOrderConfig['products'].map((dim) => {
            //         if (rowColConfigs.find((config) => config.dim === dim)) {
            //             return rowColConfigs.find((config) => config.dim === dim) as RowColConfig;
            //         } else {
            //             // if the dim isn't found in previous value of rowColConfig, then its time to create one object for it
            //             return {
            //                 // we want to preserve the axis as any other product dim, rest is all default values
            //                 axis: rowColConfigs.filter((config) => config.type === 'products')[0].axis,
            //                 type: 'products',
            //                 dim: dim,
            //                 values: [],
            //                 sortType: 'default',
            //                 stack: false,
            //                 hide: false,
            //                 pageBy: false,
            //             } as RowColConfig;
            //         }
            //     });

            //     // finally, combine both product's rowColConfig and non-product's rowColConfig and return the value per overallUiOrder
            //     const combinedRowCollConfig = [...nonProducts, ...products];
            //     updatedRowColConfigs = runConfig.layout.overallUiOrder
            //         .map((type) => combinedRowCollConfig.filter((rcc) => rcc.type === type))
            //         .flat();

            //     return updatedRowColConfigs;
            // });
    //     }
    // }, [runConfig.layout.savedLayoutId]);

    // reset conditions everytime fact changes
    useEffect(() => {
        const formattedFactNames = value.facts.map((fact) => factSetToDisplayNames(fact)).flat(Infinity);
        const conditions = value.conditions.filter((condition) =>
            formattedFactNames.some((fact) => condition.fact === fact)
        );
        // const newQuickLayoutCode =
        // runConfig.layout.quickLayoutCode && !runConfig.layout.savedLayoutId && !runConfig.layout.templateId
        //     ? runConfig.layout.quickLayoutCode
        //     : runConfig.layout.savedLayoutId && conditions.length
        //     ? runConfig.layout.quickLayoutCode
        //     : 'cl';
        // const newSavedLayoutId = newQuickLayoutCode ? undefined : runConfig.layout.savedLayoutId;
        applyValues((prevValue) => {
            return {
                ...prevValue,
                timePeriodsReordered: false,
                rowColConfigs,
                runConfig: {
                    ...prevValue.runConfig,
                    layout: {
                        ...prevValue.runConfig.layout,
                        // quickLayoutCode: newQuickLayoutCode,
                        // savedLayoutId: newSavedLayoutId,
                    },
                    conditions,
                },
            };
        });
    }, [value.facts]);

    function handleLayoutChange(e: ByzzerChangeEvent<DodLayoutConfig>): void {
        setRunConfig((config) => {
            handleUndoStates({ layout: config.layout });
            return {
                ...config,
                layout: e.value,
            };
        });
    }

    function getPresetLayoutOrderAndCurrentLayoutOrder(
        savedLayoutId: number
    ): { presetOrderConfigs: OrderConfig; currentOrderConfigs: OrderConfig; presetRowsAndColumns: DodAxisDef[] } {
        // get the saved layout config based on savedLayoutId selected by user
        const savedLayout = getSavedLayoutById(Number(savedLayoutId));

        const { rows, columns } = (savedLayout?.values as {
            layout: DodLayoutConfig;
            conditions: FactCondition[];
        }).layout;
        const rowsAndColumns = [...rows, ...columns];

        // get the values and its order stored for each dimension (products, markets, facts, time_periods)
        // from the selected preset's layout config and put it inside a json map
        const presetOrderConfigs: OrderConfig = rowsAndColumns.reduce(
            (mapping, rc) => ({
                ...mapping,
                [rc.type]: rc.order,
            }),
            {}
        ) as OrderConfig;
        // we know products ordering needs special care, which is happening below
        const productsOrderInPreset = rowsAndColumns.filter((rc) => rc.type === 'products').map((rc) => rc.dim);
        presetOrderConfigs['products'] = productsOrderInPreset;

        // similarily, get the values and its order stored at the moment from context, based on user's selection
        // and create a map for each dimension (products, markets, time_periods and facts)
        const currentOrderConfigs: OrderConfig = rowColConfigs.reduce(
            (mapping, rc) => ({
                ...mapping,
                [rc.type]: rc.values,
            }),
            {}
        ) as OrderConfig;
        // again special care for products order below
        // (if you're not sure why this special care, its because of how rowColConfig is structured)
        const productsOrderInContext = rowColConfigs.filter((rcc) => rcc.type === 'products').map((rcc) => rcc.dim);
        currentOrderConfigs['products'] = productsOrderInContext;

        return {
            presetRowsAndColumns: rowsAndColumns,
            presetOrderConfigs,
            currentOrderConfigs,
        };
    }

    function getSortedMarkets(sortType: SortType): string[] {
        const { values, summedSelections } = runConfig.filters.markets;
        let sortedMarkets: string[] = [];
        if (['asc', 'desc'].includes(sortType)) {
            sortedMarkets = values
                .map((market) => market.name)
                .sort(sortType === 'asc' ? caseInsensitiveSort : caseInsensitiveReverseSort);
        }
        return [...sortedMarkets, ...summedSelections.map((ss) => ss.name)];
    }

    function getSortedTimePeriods(sortType: SortType): string[] {
        const { values, summedSelections } = runConfig.filters.timePeriods;
        let sortedTimePeriods: string[] = [];
        if (['asc', 'desc'].includes(sortType)) {
            sortedTimePeriods = values
                .flat()
                .map((tp) => new TimePeriodRange(tp, maxDate))
                .sort(sortType === 'asc' ? TimePeriodRange.compareAsc : TimePeriodRange.compareDesc)
                .map((tp) => tp.toLegacyDodString());
        }
        return [...sortedTimePeriods, ...summedSelections.map((ss) => ss.name)];
    }

    async function handlePresetLayoutChange(e: ByzzerChangeEvent<string>): Promise<void> {
        if (e.value === 'cl') {
            // custom layout doesn't impact the layout in anyway
            setRunConfig((config) => {
                handleUndoStates({ layout: config.layout });
                return {
                    ...config,
                    layout: {
                        ...value.layout,
                        quickLayoutCode: 'cl',
                        templateId: undefined,
                        savedLayoutId: undefined,
                    },
                };
            });
        } else {
            const isQuickLayout = e.value.startsWith('ql');
            const wasQuickLayout = layoutId?.startsWith('ql');

            if (
                isQuickLayout &&
                timePeriodsReordered &&
                !(await confirm({
                    title: 'Warning',
                    content: (
                        <>
                            <p>Applying a quick layout will revert your time periods back to their default order.</p>
                            <p>Do you want to continue?</p>
                        </>
                    ),
                    yesLabel: 'Yes',
                    noLabel: 'No, Cancel',
                    suppressable: true,
                    suppressMessage: 'Do not show this message again for the remainder of this run session.',
                    suppressed: suppressQuickLayoutWarning,
                    onSuppress() {
                        setSuppressQuickLayoutWarning(true);
                    },
                }))
            ) {
                return;
            }

            const quickLayoutCode = isQuickLayout ? e.value : undefined;
            const isSavedLayout = Object.keys(e.data.data).includes('conditions');
            const savedLayoutId = isQuickLayout ? undefined : isSavedLayout ? Number(e.value) : undefined;
            const templateId = isQuickLayout || isSavedLayout ? undefined : Number(e.value);

            let includeCategoryTotals = value.layout.includeCategoryTotals;
            let includeSubTotals = value.layout.includeSubTotals;
            let includeExtractDate = value.layout.includeExtractDate;

            let conditions = value.conditions;

            let newRowColConfigs: RowColConfig[] = [];

            if (isSavedLayout) {
                if (mode !== 'layout') {
                    const savedConditions = e.data.data.conditions;
                    const formattedFactNames = value.facts.map((fact) => factSetToDisplayNames(fact)).flat(Infinity);
                    conditions = savedConditions.filter((condition) =>
                        formattedFactNames.some((fact) => condition.fact === fact)
                    );

                    includeCategoryTotals = e.data.data.layout.includeCategoryTotals;
                    includeSubTotals = e.data.data.layout.includeSubTotals;
                    includeExtractDate = e.data.data.layout.includeExtractDate;
                }

                const {
                    presetOrderConfigs,
                    currentOrderConfigs,
                    presetRowsAndColumns,
                } = getPresetLayoutOrderAndCurrentLayoutOrder(Number(e.value));

                // find what values are common between preset's order and current selection order and put it in a json map
                const intersectionOrderConfig = {
                    products: intersection(presetOrderConfigs['products'], currentOrderConfigs['products']),
                    markets: intersection(presetOrderConfigs['markets'], currentOrderConfigs['markets']),
                    time_periods: intersection(presetOrderConfigs['time_periods'], currentOrderConfigs['time_periods']),
                    facts: intersection(presetOrderConfigs['facts'], currentOrderConfigs['facts']),
                };

                // find what values present in current selection, but not in presets
                // we want this because thats what the user has selected to run this dod
                // we dont care of the values present in preset and not in current selection,
                // simply because user did not select them for this run
                const differenceOrderConfig = {
                    products: difference(currentOrderConfigs['products'], presetOrderConfigs['products']),
                    markets: difference(currentOrderConfigs['markets'], presetOrderConfigs['markets']),
                    time_periods: difference(currentOrderConfigs['time_periods'], presetOrderConfigs['time_periods']),
                    facts: difference(currentOrderConfigs['facts'], presetOrderConfigs['facts']),
                };

                const marketSort = presetRowsAndColumns.find(rcc => rcc.type === 'markets')?.sortType;
                const timePeriodSort = presetRowsAndColumns.find(rcc => rcc.type === 'time_periods')?.sortType;

                // as per requirement discussed for ordering in BYZ-8480, when a saved layout is applied in layout step,
                // we place the common values first and then append the remaining values from current selection,
                // which is happening below for all dimensions
                const resultOrderConfig = {
                    products: [...intersectionOrderConfig['products'], ...differenceOrderConfig['products']],
                    markets:
                        marketSort === 'asc' || marketSort === 'desc'
                            ? getSortedMarkets(marketSort)
                            : [...intersectionOrderConfig['markets'], ...differenceOrderConfig['markets']],
                    time_periods:
                        timePeriodSort === 'asc' || timePeriodSort === 'desc'
                            ? getSortedTimePeriods(timePeriodSort)
                            : [...intersectionOrderConfig['time_periods'], ...differenceOrderConfig['time_periods']],
                    facts: [...intersectionOrderConfig['facts'], ...differenceOrderConfig['facts']],
                };

                const presetRowColConfigs = applyPresetLayout(e.data.data.layout)

                // for markets, timeperiods and facts, its pretty straight-forward, we just need to update the 'values',
                // other config like axis, stack, page, type, dim all remains the same
                const nonProducts = presetRowColConfigs
                    .filter((config) => config.type !== 'products')
                    .map((config) => ({
                        ...config,
                        values: resultOrderConfig[config.type],
                    }));

                // however for products, we search if the dim already exists in previous rowColConfig,
                // if present, just return that object and not modify anything, as we want to preserve
                // its config like page, stack, hide which user might have selected
                const products: RowColConfig[] = resultOrderConfig['products'].map((dim) => {
                    if (presetRowColConfigs.find((config) => config.dim === dim)) {
                        if (dim === 'categories' && hasPartiallyApprovedMarkets) {
                            return {
                                ...(presetRowColConfigs.find((config) => config.dim === dim) as RowColConfig),
                                hide: false,
                                stack: false,
                            };
                        }
                        return presetRowColConfigs.find((config) => config.dim === dim) as RowColConfig;
                    } else {
                        // if the dim isn't found in previous value of rowColConfig, then its time to create one object for it
                        return {
                            // we want to preserve the axis as any other product dim, rest is all default values
                            axis: presetRowColConfigs.filter((config) => config.type === 'products')[0].axis,
                            type: 'products',
                            dim: dim,
                            values: [],
                            sortType: 'default',
                            stack: false,
                            hide: false,
                            pageBy: false,
                        } as RowColConfig;
                    }
                });

                // finally, combine both product's rowColConfig and non-product's rowColConfig and return the value per overallUiOrder
                const combinedRowColConfig = [...nonProducts, ...products];
                const overallOrderForPreset = uniq(presetRowColConfigs.map(prcc => prcc.type));
                newRowColConfigs = overallOrderForPreset // to get the overall order to be applied from selected saved preset
                    .map((type) => combinedRowColConfig.filter((rcc) => rcc.type === type))
                    .flat();


                // we need this to throw a warning popup to user
                const excludedPresetSelections = {
                    products: difference(presetOrderConfigs['products'], currentOrderConfigs['products']),
                    markets: difference(presetOrderConfigs['markets'], currentOrderConfigs['markets']),
                    time_periods: difference(presetOrderConfigs['time_periods'], currentOrderConfigs['time_periods']),
                    facts: difference(presetOrderConfigs['facts'], currentOrderConfigs['facts']),
                };

                const excludedProducts = excludedPresetSelections['products'].map((dim) =>
                    presetRowsAndColumns.find((rcc) => rcc.dim === dim)
                );
                const pageBySelected = excludedProducts.filter((product) => (product as DodRowDef)?.pageBy);
                const stackBySelected = excludedProducts.filter((product) => (product as DodRowDef)?.stack);

                let alertContent = '';
                if (pageBySelected.length) {
                    alertContent = `This layout includes Page By for ${pageBySelected
                        .map((product) =>
                            SUB_DIMENSION_VALUE_TO_DISPLAY[product?.dim!]
                                ? SUB_DIMENSION_VALUE_TO_DISPLAY[product?.dim!]
                                : product?.dim
                        )
                        .join(', ')}. You have not included ${pageBySelected
                        .map((product) =>
                            SUB_DIMENSION_VALUE_TO_DISPLAY[product?.dim!]
                                ? SUB_DIMENSION_VALUE_TO_DISPLAY[product?.dim!]
                                : product?.dim
                        )
                        .join(', ')} in this run, so it will not be included in the Page By.`;
                }

                if (stackBySelected.length) {
                    alertContent = `This layout includes Stacking for ${stackBySelected
                        .map((product) =>
                            SUB_DIMENSION_VALUE_TO_DISPLAY[product?.dim!]
                                ? SUB_DIMENSION_VALUE_TO_DISPLAY[product?.dim!]
                                : product?.dim
                        )
                        .join(', ')}. You have not included ${stackBySelected
                        .map((product) =>
                            SUB_DIMENSION_VALUE_TO_DISPLAY[product?.dim!]
                                ? SUB_DIMENSION_VALUE_TO_DISPLAY[product?.dim!]
                                : product?.dim
                        )
                        .join(', ')} in the run, so it will not be Stacked.`;
                }

                if (pageBySelected.length && stackBySelected.length) {
                    alertContent = `This layout includes Page By for ${pageBySelected
                        .map((product) =>
                            SUB_DIMENSION_VALUE_TO_DISPLAY[product?.dim!]
                                ? SUB_DIMENSION_VALUE_TO_DISPLAY[product?.dim!]
                                : product?.dim
                        )
                        .join(', ')} and Stacking for ${stackBySelected
                        .map((product) =>
                            SUB_DIMENSION_VALUE_TO_DISPLAY[product?.dim!]
                                ? SUB_DIMENSION_VALUE_TO_DISPLAY[product?.dim!]
                                : product?.dim
                        )
                        .join(
                            ', '
                        )}. You have not included them in this run, so it will not be included in the Page By and will not be Stacked.`;
                }
                if (pageBySelected.length || stackBySelected.length) {
                    byzzerAlert({
                        content: alertContent,
                    });
                }
            } else {
                newRowColConfigs = applyPresetLayout(e.data.data);
            }

            // newRowColConfigs = isSavedLayout
            // ? applyPresetLayout(e.data.data.layout)
            // : applyPresetLayout(e.data.data);

            // if we're changing to a quick layout reapply default time period ordering
            if (isQuickLayout && !wasQuickLayout) {
                const { values, summedSelections } = runConfig.filters.timePeriods;
                const orderedTimePeriods: string[] = values
                    .flat()
                    .map((tp) => new TimePeriodRange(tp, maxDate))
                    .sort(TimePeriodRange.compareDesc)
                    .map((tp) => tp.toLegacyDodString());

                // update the order in the row/cols
                newRowColConfigs.forEach((config) => {
                    if (config.type === 'time_periods') {
                        config.values = [...orderedTimePeriods, ...summedSelections.map((ss) => ss.name)];
                    }
                });
            }
            const layout: DodLayoutConfig = {
                ...runConfig.layout,
                quickLayoutCode,
                templateId,
                savedLayoutId,
                includeCategoryTotals,
                includeSubTotals,
                includeExtractDate,
                overallUiOrder: uniq(newRowColConfigs.map((config) => config.type)),
                rows: newRowColConfigs.filter((v) => v.axis === 'row').map(toAxisDef) as DodRowDef[],
                columns: newRowColConfigs.filter((v) => v.axis === 'col').map(toAxisDef) as DodColDef[],
            };
            // set the base config based on the quick layout
            applyValues((prevValue) => {
                handleUndoStates({
                    timePeriodsReordered: prevValue.timePeriodsReordered,
                    rowColConfig: prevValue.rowColConfigs,
                    layout: prevValue.runConfig.layout,
                    conditions: prevValue.runConfig.conditions,
                });
                return {
                    ...prevValue,
                    timePeriodsReordered: false,
                    rowColConfigs: newRowColConfigs,
                    runConfig: {
                        ...prevValue.runConfig,
                        layout,
                        conditions,
                    },
                };
            });
        }

        // layout change tracking
        trackEvent({
            type: 'click',
            name: 'dod_preset_layout_change',
            data: getEventData({ dodWizardStep: 'layout', layout: isNaN(Number(e.value)) ? e.value : 'template' }),
        });
    }

    function applyPresetLayout(presetConfig: DodPresetConfig): RowColConfig[] {
        const { axes, order, dimConfigs } = cloneDeep(presetConfig);

        return order
            .map((type) => {
                const axis = axes[type];

                return rowColConfigs
                    .filter((rcc) => rcc.type === type)
                    .map((rcc) => {
                        const dimConfig: Partial<RowColConfig> = {
                            stack: false,
                            hide: false,
                            pageBy: false,
                            sortType: 'default',
                            ...dimConfigs[rcc.dim],
                        };

                        return {
                            ...rcc,
                            axis,
                            ...dimConfig,
                        };
                    });
            })
            .flat();
    }

    function handleConditionsClear(): void {
        setRunConfig((config) => {
            handleUndoStates({ conditions: config.conditions });
            return {
                ...config,
                conditions: [],
            };
        });
    }

    async function handleConditionsEdit(): Promise<void> {
        const conditions = await openDodConditionBuilderModal({
            runConfig: value,
        });

        if (conditions) {
            setRunConfig((config) => {
                handleUndoStates({ conditions: config.conditions });
                return {
                    ...config,
                    conditions,
                };
            });
        }

        // conditions button click tracking
        trackEvent({
            type: 'click',
            name: 'dod_conditions_click',
            data: getEventData({ dodWizardStep: 'layout' }),
        });
    }

    function handleUndoStates(config: UndoState): void {
        const undoState: UndoState = {
            ...(config?.timePeriodsReordered !== undefined && { timePeriodsReordered: config.timePeriodsReordered }),
            ...(config?.conditions && { conditions: config.conditions }),
            ...(config?.layout && { layout: config.layout }),
            ...(config?.rowColConfig && { rowColConfig: config?.rowColConfig }),
        };
        addStateToHistory(undoState);
    }

    function handleUndo(): void {
        if (!canUndo) return;

        const undoState = undo();
        if (!undoState) return;

        const { rowColConfig, layout, timePeriodsReordered, conditions } = undoState;

        const updates: Partial<DodRunConfig> = {};
        if (layout) updates.layout = layout;
        if (conditions) updates.conditions = conditions;

        if (rowColConfig) {
            setRowColConfigs(rowColConfig);
            applyValues((prevValue) => ({
                ...prevValue,
                rowColConfigs: rowColConfig,
                ...(timePeriodsReordered !== undefined && { timePeriodsReordered }),
            }));
        } else if (timePeriodsReordered !== undefined) {
            setTimePeriodsReordered(timePeriodsReordered);
        }

        if (Object.keys(updates).length > 0) {
            setRunConfig((prevConfig) => ({
                ...prevConfig,
                ...updates,
            }));
        }

        trackEvent({
            type: 'click',
            name: 'dod_layout_undo_click',
            data: getEventData({ dodWizardStep: 'layout' }),
        });
    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
                handleUndo();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleUndo]);

    const handleSaveSelections = async () => {
        setSaveSelectionModalOpened(true);
        trackEvent({
            type: 'click',
            name: 'dod_save_selections_click',
            data: getEventData({ dodWizardStep: savedSelectionType, panel: 'layout' }),
        });

        const preset = await openCreateDodPresetModal({
            title: 'Save Your Layout',
            description: savedSelectionDescription,
            preset: {
                type: savedSelectionType,
                values: {
                    layout: { ...value.layout, overallUiOrder: uniq(rowColConfigs.map((config) => config.type)) }, // need to override overallUiOrder property in context while saving layout
                    conditions: value.conditions,
                },
            },
            // currentPreset: presets[savedSelectionType],
            dodPresetsMap: dodPresetsMap['layout'],
            duplicateNameErrorTip:
                'Your company already has a layout saved with this name. Please choose a different name.',
        });

        if (preset) {
            setPreset(savedSelectionType, {
                ...preset,
                // @ts-ignore - this needs to be here to remove values and produce a valid preset summary
                values: undefined,
            });
            reloadDodPresets();
            setRunConfig((config) => {
                handleUndoStates({
                    layout: {
                        ...config.layout,
                    },
                });
                return {
                    ...config,
                    layout: {
                        ...value.layout,
                        templateId: undefined,
                        quickLayoutCode: undefined,
                        savedLayoutId: preset.id,
                    },
                };
            });
        }
        setSaveSelectionModalOpened(false);
    };

    function hasAccessToDelete(savedLayoutId: number): boolean {
        let hasAccess = false;

        const loggedInUserId = user?.authorizedUserId;
        const savedLayout = dodPresetsMap.layout.find(
            (layoutPreset) => Number(layoutPreset.id) === Number(savedLayoutId)
        );

        if (savedLayout && savedLayout.authorizedUserId === loggedInUserId) {
            hasAccess = true;
        }
        return hasAccess;
    }

    const CustomLayoutOption = (props) => {
        return (
            <span className={`${baseClassName}__value-node`}>
                <span className={`${baseClassName}__value-node--label`}>{props.children}</span>
                {hasAccessToDelete(props.value) && (
                    <i
                        className={`${baseClassName}__value-node--delete`}
                        onClick={() => handleSavedSelectionDelete(Number(props.data.value))}
                    />
                )}
            </span>
        );
    };

    async function handleSavedSelectionDelete(id: number): Promise<void> {
        openModal({
            title: 'Delete Saved Selection',
            showCloseOption: false,
            content: ({ busy }) => {
                return (
                    <>
                        {busy ? (
                            <p>Removing Saved Selection...</p>
                        ) : (
                            <p>Are you sure you want to delete this Saved Selection?</p>
                        )}
                    </>
                );
            },
            actions: [
                {
                    key: 'cancel',
                    label: 'No, Cancel',
                    type: 'negative',
                    action({ reject }) {
                        reject();
                    },
                    disableIf: ({ busy }) => (busy ? true : false),
                },
                {
                    key: 'yes',
                    label: 'Yes',
                    async action({ resolve, setBusy }) {
                        try {
                            setBusy(true);
                            await deletePreset(id);
                            resolve(true);
                        } finally {
                            setBusy(false);
                        }
                    },
                    disableIf: ({ busy }) => (busy ? true : false),
                },
            ],
        });
    }

    return (
        <DodPanel
            className={classnames(baseClassName, className)}
            title={title}
            expandable={true}
            name={'dod-layout-editor'}
            enableFilter={false}
            actions={[
                {
                    key: 'undo',
                    disabled: !canUndo,
                    iconType: 'undo',
                    tip: 'Undo your selections',
                    onClick: handleUndo,
                },
                {
                    key: 'save',
                    disabled: false,
                    iconType: 'save',
                    tip: 'Save your current layout selections to use in future runs.',
                    onClick: handleSaveSelections,
                },
            ]}
            forceClosePanel={saveSelectionModalOpened}
        >
            <ByzzerSelect
                value={layoutId}
                className={`${baseClassName}__preset-picker`}
                options={layoutOptions}
                label={'Preset & Saved Layouts:'}
                placeholder="Select Your Layout"
                onChange={handlePresetLayoutChange}
                disabledOptions={disabledQuickLayoutOptions}
                optionComponent={CustomLayoutOption}
            />
            <DodDesignYourLayout runConfig={runConfig} value={value.layout} handleUndoStates={handleUndoStates} />
            <DodLayoutOptions value={runConfig.layout} onChange={handleLayoutChange} mode={mode} />
            <DodLayoutConditionBuilder
                value={runConfig.conditions}
                facts={runConfig.facts}
                onClear={handleConditionsClear}
                onEdit={handleConditionsEdit}
                mode={mode}
            />
        </DodPanel>
    );
}

function toDodPresetConfig(layout: DodLayoutConfig): DodPresetConfig {
    const { rows, columns, overallUiOrder } = layout;

    const rowsAndColumns = [...rows, ...columns];

    // convert the array of rows and columsn into their respective configs
    const dimConfigs = rowsAndColumns.reduce(
        (mapping, rc) => ({
            ...mapping,
            [rc.dim]: {
                stack: (rc as DodRowDef).stack,
                pageBy: rc.pageBy,
                hide: rc.hide,
                sortType: rc.sortType,
            },
        }),
        {}
    );

    // determine which axis each dimenison type is on. it has to be row or col so if it's not row, you guessed it, it's col
    const products = rows.find((row) => row.type === 'products') ? 'row' : 'col';
    const markets = rows.find((row) => row.type === 'markets') ? 'row' : 'col';
    const time_periods = rows.find((row) => row.type === 'time_periods') ? 'row' : 'col';
    const facts = rows.find((row) => row.type === 'facts') ? 'row' : 'col';

    return {
        axes: {
            products,
            markets,
            time_periods,
            facts,
        },
        dimConfigs,
        order: overallUiOrder,
    };
}

export default DodLayoutEditor;
