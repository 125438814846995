import {TimePeriod} from "./TimePeriod";

const legacyMonthMapping = {
    '4': 'January',
    '8': 'February',
    '13': 'March',
    '17': 'April',
    '21': 'May',
    '26': 'June',
    '30': 'July',
    '34': 'August',
    '39': 'September',
    '43': 'October',
    '47': 'November',
    '0': 'December',
}

export class TimePeriodRange {

    private type: string;
    private duration: number;
    private offset: number;
    private endDate: string | undefined;

    constructor(range: string, endDateCode?: string) {
        const [type, rawDuration, rawOffset] = range.split(':');

        if(!type || !rawDuration || !rawOffset) {
            throw new Error('Invalid TimePeriodRange');
        }

        this.type = type;
        this.duration = Number(rawDuration);
        this.offset = Number(rawOffset);
        this.endDate = endDateCode;
    }

    toLegacyDodString(): string {

        const {type, duration, offset, endDate} = this;

        switch (type) {

            case 'pe1y':
            case 'ytd':
            case 'pp':
            case 'pp1y':
            case 'pe':
                let prefix: string;
                const year = this.type === "cpe" ? (offset / 52) : offset;
                const isYear = year % 1 === 0;
                const normalizedOffset = isYear ? year : offset;
                const offsetUnit = year % 1 === 0 ? 'Year-Ago' : 'Week-Ago';

                if (type === 'pe1y' || type === 'ytd' || type === 'pp1y') {
                    prefix = `Year to Date`;
                } else {
                    prefix = `${type === "pp" ? 'Previous' : 'Latest'} ${duration} Week${duration === 1 ? '' : 's'}`;
                }

                if (normalizedOffset > 0) {
                    if (normalizedOffset === 1) {
                        return `${prefix} ${offsetUnit}`;
                    }

                    if (normalizedOffset > 1) {
                        return `${prefix} ${normalizedOffset}-${offsetUnit}`;
                    }
                }
                return prefix;
            case '1w':
                return `1 Week - W/E ${new TimePeriod(offset).toLegacyString()}`;
            case '4w':
                return `4 Wks - W/E ${new TimePeriod(offset).toLegacyString()}`;
            case '4s':
                return `4 Wks (Static) - W/E ${new TimePeriod(offset).toLegacyString()}`;
            case '1m': {
                const month = legacyMonthMapping[offset % 52];
                const year = 2018 + Math.floor((offset - 1) / 52);
                return `${month} ${year} - W/E ${new TimePeriod(offset).toLegacyString()}`;
            }
            case '1q':
                return `Quarter - W/E ${new TimePeriod(offset).toLegacyString()}`;
            case '1y': {
                const year = 2018 + Math.floor((offset - 1) / 52);
                return `Year ${year} - W/E ${new TimePeriod(offset).toLegacyString()}`;
            }
            case 'cpe': {
                try {
                  const year = offset / 52;
                  const isYear = year % 1 === 0;
                  const normalizedOffset = isYear ? year : offset;

                  if (normalizedOffset > 0) {
                      return `Custom ${duration} Week - W/E ${new TimePeriod(endDate!).subtract(isYear ? normalizedOffset * 52 : normalizedOffset).toLegacyString()}`
                  }
                  return `Custom ${duration} Week - W/E ${new TimePeriod(endDate!).toLegacyString()}`;
                } catch (error) {
                  console.error("Error converting time-period to legacy Dod string:", error);
                }
                return `Custom ${duration} Week - W/E ${new TimePeriod(offset).toLegacyString()}`;
            }
            case 'cw':
                return `Custom ${duration} Week - W/E ${new TimePeriod(offset).toLegacyString()}`;
            default:
                return new TimePeriod(offset).toLegacyString();
        }
    }

    toString(): string {
        return this.toLegacyDodString();
    }

    toLegacyDodDateString(): string {

        const {type, duration, offset, endDate} = this;

        switch (type) {

            case 'pe1y':
            case 'ytd':
            case 'pe':

                let prefix: string;
                const year = this.type.slice(0, 2) === "cp" ? (offset / 52) : offset;
                const isYear = year % 1 === 0;
                const normalizedOffset = isYear ? year : offset;

                if (type === 'pe1y' || type === 'ytd') {
                    prefix = `Year to Date`;
                } else {
                    prefix = `Latest ${duration} Week${duration === 1 ? '' : 's'}`;
                }

                if (normalizedOffset > 0) {
                    const endingTp = new TimePeriod(endDate!).subtract(isYear ? offset * 52 : offset);
                    if (offset === 1) {
                        return `${endingTp.toDateString()}`;
                    }

                    if (offset > 1) {
                        return `${endingTp.toDateString()}`;
                    }
                }
                return `${new TimePeriod(endDate!).toDateString()}`;
            case '1w':
                return `${new TimePeriod(offset).toLegacyString()}`;
            case '4w':
                return `${new TimePeriod(offset).toLegacyString()}`;
            case '1m': {
                return `${new TimePeriod(offset).toLegacyString()}`;
            }
            case '1q':
                return `${new TimePeriod(offset).toLegacyString()}`;
            case '1y': {
                return `${new TimePeriod(offset).toLegacyString()}`;
            }
            case 'cpe': {
                try {
                  const year = offset / 52;
                  const isYear = year % 1 === 0;
                  const normalizedOffset = isYear ? year : offset;

                  if (normalizedOffset > 0) {
                      return `${new TimePeriod(endDate!).subtract(isYear ? normalizedOffset * 52 : normalizedOffset).toLegacyString()}`
                  }
                  return `${new TimePeriod(endDate!).toLegacyString()}`;
                } catch (error) {
                  console.error("Error converting time-period to legacy Dod string:", error);
                }
                return `${new TimePeriod(offset).toLegacyString()}`;
            }
            case 'cw':
                return `${new TimePeriod(offset).toLegacyString()}`;
            default:
                return new TimePeriod(offset).toLegacyString();
        }
    }

    /**
     * This does some crazy shit to ensure that time period sorting works in groups
     */
    getAscValue(): number {
        let offset = this.offset;
        let duration = this.duration;

        // period ending ranges have have to sort of work in reverse because they offset is relative to the max end date
        if(['pe1y', 'ytd', 'pe', 'pp'].includes(this.type)) {

            // we are applying a multiplier to ytd/pe1y to ensure it comes last
            if(this.type !== 'pe') {
                duration *= 10;
            }
            return TYPE_SORT_VALUE[this.type] + (offset * 10_000) - duration;
        }

        if(this.type === 'cpe') {
          // To group by custom time period values, we are multiplying the duration
          return TYPE_SORT_VALUE[this.type] + (duration * 10_000) - offset;
        }

        return TYPE_SORT_VALUE[this.type] - (offset * 10_000) - duration;
    }

    /**
     * This does some crazy shit to ensure that time period sorting works in groups
     */
    getDescValue(): number {

        let offset = this.offset;
        let duration = this.duration;


        // period ending ranges have have to sort of work in reverse because they offset is relative to the max end date
        if(['pe1y', 'ytd', 'pe', 'pp'].includes(this.type)) {

            // we are applying a multiplier to ytd/pe1y to ensure it comes last
            if (this.type !== 'pe') {
                duration *= 10;
            }
            return TYPE_SORT_VALUE[this.type] - (offset * 10_000) - duration;
        }

        if(this.type === 'cpe') {
          // To group by custom time period values, we are multiplying the duration
          return TYPE_SORT_VALUE[this.type] - (duration * 10_000) - offset;
        }

        return TYPE_SORT_VALUE[this.type] + (offset * 10_000) - duration;
    }

    static compareAsc(a: TimePeriodRange, b: TimePeriodRange): number {
        return b.getAscValue() - a.getAscValue();
    }

    static compareDesc(a: TimePeriodRange, b: TimePeriodRange): number {
        return b.getDescValue() - a.getDescValue();
    }
}

const TYPE_SORT_VALUE = {
    'pe1y': 900_000_000,
    'ytd': 900_000_000,
    'pe': 900_000_000,
    'pp': 800_000_000,
    '1w': 700_000_000,
    '4w': 600_000_000,
    '4s': 500_000_000,
    '1m': 400_000_000,
    '1q': 300_000_000,
    '1y': 200_000_000,
    'cpe': 100_000_000,
    'cw': 100_000_000,
}
