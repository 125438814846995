import { useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useUser } from '@/contexts/UserContext';
import className from 'classnames';
import '@/pages/StoryViewer/BuildStory.scss';
import { ByzzerTable } from '@/components/ByzzerTable';
import { ByzColDef } from '@/types/TableTypes';
import { FLOATING_DATE_PICKER_FILTER_PARAMS } from '@/constants/table.constants';
import { NavLink } from 'react-router-dom';
import {openCharEditor} from './editor/CharEditor';
import { useTenantApi } from '@/hooks/useTenantApi';
import { confirm } from '@/components/form/ByzzerModal';
import classnames from 'classnames';
import './CustomCharacteristicsGrid.scss';
import { formatInTimeZone } from 'date-fns-tz';

type CustomCharacteristicsColId = 'characteristics' | 'createdBy' | 'createdDtm' | 'categories' | 'type' | 'actions';

const CustomCharacteristicsGrid = ({}) => {
    const baseClassName = className('custom-characteristics-table');
    const { customCharacteristics } = useUser();
    const {deleteCustomChar} = useTenantApi();
    const [customCharRowData, setCustomCharRowData] = useState<(Omit<CustomCharacteristic, 'createdDtm'> & {createdDtm: string})[]>([]);  

    useEffect(() => {
        loadCustomChars();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customCharacteristics]);

    function loadCustomChars() {
        const sortedCustomChars = customCharacteristics.sort((a, b) => b.createdDtm.localeCompare(a.createdDtm));            
        setCustomCharRowData(sortedCustomChars.map(item => ({
            ...item,
            createdDtm: formatInTimeZone(new Date(item.createdDtm), 'America/New_York', 'yyyy-MM-dd')
        })));
    }; 

    async function handleEdit(charId: CustomCharacteristic['id']) {
        // @ts-ignore
        if (await openCharEditor({charId})) {
            console.log("Opened Successfully")
        }
    }

    async function handleDelete(idToDelete) {
        if (await confirm({
            title: 'Remove Custom Characteristics',
            content: <>
                <p>Are you sure you want to remove this Characteristics?</p>
            </>,
            yesLabel: 'Yes, Remove'
        })) {
            try {
                await deleteCustomChar(idToDelete)
            } catch (err) {
                // tbd
            }
        }
    }

    const gridRef = useRef<AgGridReact>(null);

    const defaultCustomCharacteristicsColDef = useMemo(
        () => ({
            filter: true, // make every column use 'text' filter by default
            sortable: true,
            floatingFilter: true, // enable floating filters by default
            resizable: true, //set each col resizable
            width: 200, // set every column width
            flex: 0, // flex: 1,
            autoHeight: false,
            wrapText: false,
            suppressMenu: true,
            lockVisible: true,
        }),
        []
    );

    const [customCharacteristicsColumnDefs] = useState<ByzColDef<CustomCharacteristic, CustomCharacteristicsColId>[]>([
        {
            colId: 'characteristics',
            headerName: 'Characteristics',
            width: 300,
            valueGetter: ({ data }) => data.label,
            tooltipValueGetter: ({ data }) => data.label,
            pinned: 'left',
        },
        {
            colId: 'createdBy',
            headerName: 'Created By',
            width: 200,
            valueGetter: ({ data }) => {
                return `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`;
            },
            tooltipValueGetter: ({ data }) => {
                return `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`;
            },
        },
        {
            ...FLOATING_DATE_PICKER_FILTER_PARAMS,
            headerName: 'Created Date',
            colId: 'createdDtm',
            field:'createdDtm'
        },
        {
            headerName: 'Category(s)',
            colId: 'categories',
            width: 400,
            valueGetter: ({ data }) => data.categories,
            tooltipValueGetter: ({ data }) => data.categories
        },
        {
            headerName: 'Type',
            colId: 'type',
            width: 250,
            valueGetter: ({ data }) => {
                return data.type === 'UPC' ? 'Upload' : 'Characteristic Groups';
            },
            tooltipValueGetter: ({ data }) => {
                return data.type === 'UPC' ? 'Upload' : 'Characteristic Groups';
            },
        },
        {
            headerName: '',
            colId: 'actions',
            suppressMenu: true,
            sortable: false,
            filter: false,
            floatingFilter: false,
            resizable: false,
            pinned: 'right',
            width: 150,
            cellRenderer: (params) => {
                const { data } = params;
                return (
                    <div className={`${baseClassName}__actions`}>
                        <div className={`${baseClassName}__actions-edit`}>
                            {data.isEditable && (
                                <span className={`${baseClassName}__actions-edit-icon`} onClick={() => handleEdit(data?.id)}/>
                            )}
                        </div>
                        <span className={`${baseClassName}__actions-delete-icon`} onClick={() => handleDelete(data?.id)} />
                        <NavLink to={`/dashboard/custom_characteristics_report/${data?.id}`}>
                            <span className={`${baseClassName}__actions-view-icon`}/>
                        </NavLink>
                    </div>
                );
            },
        },
    ]);

    return (
        <>
        <section className={classnames(`${baseClassName}__filters-section`)}>
            <span className={classnames(`${baseClassName}__title`)}>Characteristics created by your team</span>
        </section>
            <ByzzerTable
                ref={gridRef} // Ref for accessing Grid's API
                rowData={customCharRowData} // Row Data for Rows
                columnDefs={customCharacteristicsColumnDefs} // Column Defs for Columns
                defaultColDef={defaultCustomCharacteristicsColDef} // Column Defs for Columns
                tableArea={'customCharacteristics'}
                enableColumnHideShow={true}
                enableSaveLayout={true}
                enableSaveSort={true}
            />
        </>
    );
};

export default CustomCharacteristicsGrid;
