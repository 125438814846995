import './DodPanel.scss';
import React, {
    CSSProperties,
    memo,
    ReactNode,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
    useTransition
} from "react";
import classnames from "classnames";
import {ByzzerButton, ByzzerButtonProps, ByzzerTextInput} from "@byzzer/ui-components";
import {ExpandablePanelToggle, useExpandablePanels} from "@/components/ExpandablePanel";
import {ByzzerMenu, ByzzerMenuProps} from "@/components/ByzzerMenu";
import {createPortal} from "react-dom";
import { DodFilterType, DodProductFilterType } from '../../types';
import { TrackClick, useEventDataWithUserInfo } from '@/analytics';
import { parseHiddenCharacters } from '@/utils';

export type DodPanelRef = {
    filterText: string;
    resetFilter(): void;
}

type DodPanelChildrenParams = {
    filterText: string;
}

export type DodPanelActionConfig = ByzzerButtonProps & {
    key: string;
}

export type DodPanelMenuConfig = ByzzerMenuProps & {
    key: string
};

type TrackClick = {
    eventName?: string;
    data?: Record<string, any>;
};

export type DodPanelProps = {
    title?: ReactNode;
    className?: string;
    enableFilter?: boolean;
    children?: ReactNode | ReactNode[];
    actions?: (DodPanelActionConfig | DodPanelMenuConfig)[];
    expandable?: boolean;
    filterPlaceholder?: string;
    onFilterChange?(value: string): void;
    byzRef?: React.Ref<DodPanelRef>
    name?: string;
    isEmpty?: boolean;
    emptyState?: ReactNode;
    filterType?: DodFilterType<DodProductFilterType>;
    trackClick?: TrackClick;
    forceClosePanel?: boolean;
    enableColumnHideShow?: boolean;
    factTriggerRef?: any;
}

const baseClassName = 'dod-panel';

export function DodPanel({
                             className,
                             expandable = false,
                             enableFilter = true,
                             title,
                             children,
                             filterPlaceholder = 'Search',
                             onFilterChange,
                             actions,
                             name,
                             byzRef,
                             isEmpty,
                             emptyState = '',
                             filterType,
                             trackClick,
                             forceClosePanel = false,
                             enableColumnHideShow = false,
                             factTriggerRef
                         }: DodPanelProps) {

    const [isPending, startTransition] = useTransition();
    const [style, setStyle] = useState<CSSProperties>()
    const [filterText, setFilterText] = useState<string>('');
    const {activePanel, getContainer} = useExpandablePanels();
    const isExpanded = activePanel === name && name !== undefined;
    const getEventData = useEventDataWithUserInfo();

    function handleFilterChange(e: ByzzerChangeEvent<string>) {
        setFilterText(e.value);
    }

    useImperativeHandle(byzRef, () => ({
        filterText,
        resetFilter() {
            setFilterText('')
        }
    }), [])

    useEffect(()=>{
        startTransition(() => {
            onFilterChange?.(filterText);
        });
    },[filterText])

    useEffect(() => {
        setFilterText('');
    }, [filterType]);

    useEffect(() => {
        const handleResize = () => {
            if (isExpanded) {
                const { top, left, width, height } = getContainer().getBoundingClientRect();
                setStyle({
                    top,
                    left,
                    width,
                    height,
                });
            } else {
                setStyle({});
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isExpanded]);

    const handlePaste = <TElement extends Element>(event: React.ClipboardEvent<TElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const clipboardData = event.clipboardData.getData('text');
    
        const delimitedString = parseHiddenCharacters({clipboardData, returnAsDelimitedString: true});

        handleFilterChange({value: delimitedString})
    };

    const content = <div style={style}
        className={classnames(baseClassName, className, {
        [`${baseClassName}--expanded`]:  isExpanded
    })}>
        <header className={`${baseClassName}__header`} onPaste={handlePaste}>
            {Boolean(title) && <h1 className={`${baseClassName}__title`}>{title}</h1>}
            {enableFilter && (
              <TrackClick
                  name={trackClick?.eventName || "dod_panel_search_focus"}
                  data={getEventData(trackClick?.data)}
              >
                  <ByzzerTextInput
                      className={classnames(`${baseClassName}__filter`, {
                          [`${baseClassName}__filter--one-line`]: Boolean(title),
                          [`${baseClassName}__filter--inline`]: !title,
                      })}
                      value={filterText}
                      onChange={handleFilterChange}
                      placeholder={filterPlaceholder}
                  />
              </TrackClick>
            )}
            {enableColumnHideShow && <div>
                <i ref={factTriggerRef} className={`${baseClassName}__column-hide-show`}/>
            </div>}
            {(expandable || Boolean(actions?.length)) && (
                <div className={`${baseClassName}__actions`}>
                    {actions?.map(({key, ...action}) => (
                        (action as DodPanelMenuConfig).items ? (
                            <DodPanelMenu key={key} {...action as DodPanelMenuProps}/>
                        ) : (
                            <ByzzerButton key={key} {...action} className={`${baseClassName}__action`}/>
                        ))
                    )}
                    {expandable && (
                        <ExpandablePanelToggle
                            tip={'Click to toggle a full screen view of this panel.'}
                            className={`${baseClassName}__action`}
                            panel={name!}
                            trackClick={{ name: 'dod_expand_view_click', data: getEventData(trackClick?.data) }}
                            forceClosePanel={forceClosePanel}
                        />
                    )}
                </div>
            )}
        </header>
        <main className={`${baseClassName}__content`}>
            {isEmpty ? (
                <div className={`${baseClassName}__empty-state`}>emptyState</div>
            ) : (
                children
            )}
        </main>
    </div>

    // if(isExpanded) {
    //     return createPortal(content, getContainer())
    // }

    return content;
}

type DodPanelMenuProps = ByzzerMenuProps;

const DodPanelMenu = memo((props: DodPanelMenuProps) => {

    const triggerRef = useRef<HTMLElement>(null);

    return <>
        <i className={`${baseClassName}__menu-trigger`} ref={triggerRef}/>
        <ByzzerMenu {...props} reference={triggerRef}
                    placement={'bottom-end'}
                    offset={[0, 2]} triggerTarget={triggerRef.current}/>
    </>
});

export default DodPanel;