import React, {useRef} from 'react';
import './ppg.scss';
import {PPGDefinition} from './PPGDefinition';
import {PPGReportList} from './PPGReportList';
import {openPPGEditor} from './editor/PPGEditor';
import PPGGrid from './PPGGrid';
import { DashboardContent } from '@/components/dashboard';

export function PPGs() {
    const gridRef = useRef(); // Ref for PPGGrid

    async function refreshPpgs() {
        if (gridRef.current) {
            gridRef.current.refresh(); // Refresh the grid
        }
    }

    async function onCreatePPG(allowUpload) {
        if (
            await openPPGEditor({
                allowUpload,
            })
        ) {
            refreshPpgs();
        }
    }

    return (
        <DashboardContent className={'ppgs'}>
            <div className={'ppgs__container'}>
                <PPGDefinition className={'ppgs__card'} onCreatePPG={onCreatePPG} />
                <PPGReportList className={'ppgs__card'} />
            </div>
            <PPGGrid ref={gridRef} />
        </DashboardContent>
    );
}
