import './DODTemplates.scss';
import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import { ByzzerTable } from '@/components/ByzzerTable';
import { DODTemplatesResponse } from '@/types/ApiTypes';
import { FLOATING_DATE_PICKER_FILTER_PARAMS } from '@/constants/table.constants';
import { useTenantApi } from '@/hooks/useTenantApi';
import { confirm, openErrorModal } from '@/components/form/ByzzerModal';
import { format, formatInTimeZone } from 'date-fns-tz';
import DeleteIcon from '@images/icons/DeleteIcon.svg';
import DownloadIcon from '@images/icons/DownloadIcon.svg';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { ByzColDef } from '@/types/TableTypes';
import ReplayIcon from '@images/icons/ReplayIcon.svg';
import { useUser } from '@/contexts/UserContext';
import { useNavigate } from 'react-router-dom';

type DodScheduleColId = 'checkbox' | 'displayName' | 'seriesName' | 'reportName' | 'layout' | 'firstName' | 'createdDate' | 'actions';

const baseClass = 'dod-templates';
const DODTemplates = ({removeTemplate, handleSelectionChange, ...props}) => {
    const { getDodExcelTemplates, getTemplateDownloadLink, deleteTemplate } = useTenantApi();
    const { subscription, user } = useUser();

    const [loading, setLoading] = useState(false);
    const [templates, setTemplates] = useState<any[]>([]);
    const navigate = useNavigate();
    const restrictManageDoDRun = props.remainingCoreRuns < 1 || !subscription?.active;
    const userRole = user?.role ?? 'user';
    const isUserMode = () => {
        return userRole !== 'viewer';
    };
    const fetchDODTemplates = useCallback(async () => {
        try {
            setLoading(true);
            const response = await getDodExcelTemplates();
            const formattedTemplates = response.map((item) => ({
                ...item,
                createdDate: item.createdDate ? format(new Date(item.createdDate), 'yyyy-MM-dd') : 'NA',
            }));
            setTemplates(formattedTemplates);
        } catch (error) {
            console.error('Error fetching templates:', error);
        } finally {
            setLoading(false);
        }
    }, [getDodExcelTemplates]);
    useEffect(() => {
        fetchDODTemplates();
        handleSelectionChange([]);
    }, []);

    const handleDownloadTemplate = async (templateId: number) => {
        try {
            setLoading(true);
            const url = await getTemplateDownloadLink(templateId);
            const link = document.createElement('a');
            link.href = url;
            link.click();
        } catch (error: any) {
            openErrorModal({
                title: 'Something Unexpected Happened',
                content: <p>Fear not our engineering team is on the job.</p>,
                errorId: error.id
            });
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteTemplate = async (template: DODTemplatesResponse) => {
        const confirmed = await confirm({
            title: 'Delete Template',
            content: (
                <div className="byzzer-allocation-warning">
                    Do you want to delete the selected Template "{template.displayName}"?
                </div>
            ),
            yesLabel: 'Yes',
            noLabel: 'No',
        });

        if (!confirmed) return;

        try {
            setLoading(true);
            await deleteTemplate(template.id);
            removeTemplate(template.id)
            setTemplates((prev) => prev.filter((item) => item.id !== template.id));
        } catch (error: any) {
            openErrorModal({
                title: 'Something Unexpected Happened',
                content: <p>Fear not our engineering team is on the job.</p>,
                errorId: error.id
            });
        } finally {
            setLoading(false);
        }
    };

    const gridRef = useRef<AgGridReact>(null);
    const defaultColDef = useMemo(
        () => ({
            filter: true, // make every column use 'text' filter by default
            sortable: true,
            floatingFilter: true, // enable floating filters by default
            resizable: true, //set each col resizable
            width: 170, // set every column width
            flex: 1,
            autoHeight: false,
            wrapText: false,
            suppressMenu: true,
            lockVisible: true
            // suppressMovable:true, to be discussed today?
        }),
        []
    );

    
    const updateSelectedRows = () => {
        const selectedNodes = gridRef?.current?.api?.getSelectedNodes();
        const selectedData = selectedNodes?.map(node => node.data);
        handleSelectionChange(selectedData);
    };
    
    useEffect(() => {
        if (gridRef.current?.api) {
            gridRef.current?.api?.addEventListener('selectionChanged', updateSelectedRows);
        }
        return () => {
            if (gridRef.current?.api) {
                gridRef.current?.api.removeEventListener('selectionChanged', updateSelectedRows);
            }
        };
    }, [templates]);

    function onModifyCloneReport(id: number, layoutOnly: boolean = false) {
        const mode = layoutOnly ? 'layout' : 'edit';
        navigate(`/dashboard/extract_editor/${id}?mode=${mode}`);
    }
    const [columnDefs] = useState<ByzColDef<DODTemplatesResponse, DodScheduleColId>[]>([
        {
            colId: 'checkbox',
            headerCheckboxSelection: true,
            checkboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            sortable: false,
            filter: false,
            resizable: false,
            width: 50,
            maxWidth: 50,
            minWidth: 50,
        },
        {
            colId: 'displayName',
            field: 'displayName',
            headerName: 'Template Name',
            width: 300,
            valueGetter: ({ data }: { data: DODTemplatesResponse }) => {
                return `${data?.displayName}`;
            },
            tooltipValueGetter: ({ data }) => {
                return `${data?.displayName}`;
            },
            disableHide: true
            // pinned: 'left',
        },
        {
            colId: 'seriesName',
            field: 'seriesName',
            width: 300,
            headerName: 'Series Name',
            valueGetter: ({ data }: { data: DODTemplatesResponse }) => {
                return `${data?.seriesName ?? ''}`;
            },
            tooltipValueGetter: ({ data }) => {
                return `${data?.seriesName}`;
            }
        },
        {
            colId: 'reportName',
            field: 'reportName',
            headerName: 'Linked DOD Run',
            width: 300,
            valueGetter: ({ data }: { data: DODTemplatesResponse }) => {
                return `${data?.reportName ?? ''}`;
            },
            tooltipValueGetter: ({ data }) => {
                return `${data?.reportName}`;
            }
        },
        {
            colId: 'layout',
            field: 'layout.quickLayoutCode',
            headerName: 'DOD Layout',
            valueGetter: ({ data }: { data: DODTemplatesResponse }) => {
                return `${data?.layout.quickLayoutCode === 'cl' ? 'Custom Layout' : 'Quick Layout'}`;
            },
            tooltipValueGetter: ({ data }) => {
                return `${data?.layout.quickLayoutCode === 'cl' ? 'Custom Layout' : 'Quick Layout'}`;
            },
        },
        {
            colId: 'firstName',
            field: 'createdBy.firstName',
            headerName: 'Created By',
            valueGetter: ({ data }: { data: DODTemplatesResponse }) => {
                return `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`;
            },
            tooltipValueGetter: ({ data }) => {
                return `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`;
            },
        },
        {
            ...FLOATING_DATE_PICKER_FILTER_PARAMS,
            colId: 'createdDate',
            field: 'createdDate',
            headerName: 'Created Date',            
        },
        {
            headerName: 'Actions',
            colId: 'actions',
            filter: false,
            floatingFilter: false,
            resizable: false,
            pinned: 'right',
            sortable: false,
            cellRenderer: (params) => {
                const { data } = params;
                return (
                    <div className={`${baseClass}__actions-cell`}>
                        <span onClick={() => handleDownloadTemplate(data.id)}>
                            <img src={DownloadIcon} alt="Download" style={{ cursor: 'pointer', marginRight: '10px' }} />
                        </span>
                        <span
                            onClick={() => {
                                onModifyCloneReport(data?.reportId, false);
                            }}
                        >
                            {isUserMode() && !restrictManageDoDRun && ( 
                                <img
                                    alt={'replay'}
                                    src={ReplayIcon}
                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                />
                            )}
                        </span>
                        <span onClick={() => handleDeleteTemplate(data)}>
                            <img src={DeleteIcon} alt="Delete" style={{ cursor: 'pointer' }} />
                        </span>
                    </div>
                );
            },
            disableHide: true
        },
    ]);
    return (
        <>
            <ByzzerMask show={loading} loading={loading}>
                Loading your templates
            </ByzzerMask>
            <div className={`${baseClass}__byzzer-table-container`}>
                <div className={`${baseClass}__byzzer-table-wrapper`}>
                    <ByzzerTable
                        ref={gridRef}
                        rowData={templates}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowSelection='multiple'
                        suppressRowClickSelection={true}
                        tableArea={'dodTemplates'}
                        enableColumnHideShow={true}
                        enableSaveLayout={true}
                    />
                </div>
            </div>
        </>
    );
};
export default DODTemplates;
