import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import '@/pages/StoryViewer/BuildStory.scss';
import { ByzzerTable } from '@/components/ByzzerTable';
import { ByzColDef } from '@/types/TableTypes';
import { FLOATING_DATE_PICKER_FILTER_PARAMS } from '@/constants/table.constants';
import { NavLink } from 'react-router-dom';
import { useTenantApi } from '@/hooks/useTenantApi';
import { confirm } from '@/components/form/ByzzerModal';
import classnames from 'classnames';
import './PPGGrid.scss';
import { formatInTimeZone } from 'date-fns-tz';
import { PPG } from '@/services/ppg.service';
import { openPPGEditor } from '@/views/PPG/editor/PPGEditor';

type PPGColId = 'listName' | 'categories' | 'createdBy' | 'lastUpdated' | 'type' | 'actions';

const PPGGrid = forwardRef(({}, ref) => {
    const baseClassName = 'ppg-table';
    const { getMyPPGs, deletePPG } = useTenantApi();
    const [ppgs, setPPGs] = useState<PPG[]>([]);

    useImperativeHandle(ref, () => ({
        refresh: async () => {
            await loadPPGs();
        },
    }));

    useEffect(() => {
        loadPPGs();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function loadPPGs() {
        const tempPPGs = await getMyPPGs();
        const sortedPPGs = tempPPGs.sort((a, b) => b.updatedDtm.localeCompare(a.updatedDtm));
        setPPGs(
            sortedPPGs.map((item) => ({
                ...item,
                updatedDtm: formatInTimeZone(new Date(item.updatedDtm), 'America/New_York', 'yyyy-MM-dd'),
            }))
        );
    }

    async function handleEdit(ppgId) {
        // @ts-ignore
        if (await openPPGEditor({ ppgId })) {
            await loadPPGs();
        }
    }

    async function handleDelete(idToDelete) {
        if (
            await confirm({
                title: 'Remove PPG List',
                content: (
                    <>
                        <p>Are you sure you want to remove this PPG list?</p>
                    </>
                ),
                yesLabel: 'Yes, Remove',
            })
        ) {
            try {
                await deletePPG(idToDelete);
                await loadPPGs();
            } catch (err) {
                // tbd
            }
        }
    }

    const defaultPPGsColDef = useMemo(
        () => ({
            filter: true, // make every column use 'text' filter by default
            sortable: true,
            floatingFilter: true, // enable floating filters by default
            resizable: true, //set each col resizable
            width: 200, // set every column width
            flex: 0, // flex: 1,
            autoHeight: false,
            wrapText: false,
            suppressMenu: true,
            lockVisible: true,
        }),
        []
    );

    const [ppgColumnDefs] = useState<ByzColDef<PPG, PPGColId>[]>([
        {
            colId: 'listName',
            headerName: 'List Name',
            width: 300,
            valueGetter: ({ data }) => data.title,
            tooltipValueGetter: ({ data }) => data.title,
            pinned: 'left',
            disableHide: true
        },
        {
            colId: 'categories',
            headerName: 'Category(s)',
            width: 350,
            valueGetter: ({ data }) => data.categories,
            tooltipValueGetter: ({ data }) => data.categories
        },
        {
            colId: 'createdBy',
            headerName: 'Created By',
            width: 200,
            valueGetter: ({ data }) => {
                return `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`;
            },
            tooltipValueGetter: ({ data }) => {
                return `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`;
            },
        },
        {
            ...FLOATING_DATE_PICKER_FILTER_PARAMS,
            headerName: 'Last Updated',
            colId: 'lastUpdated',
            field:'updatedDtm',
            width: 250
        },
        {
            headerName: 'Type',
            colId: 'type',
            width: 250,
            valueGetter: ({ data }) => {
                return data.type === 'UPC' ? 'Upload' : 'Characteristic Groups';
            },
            tooltipValueGetter: ({ data }) => {
                return data.type === 'UPC' ? 'Upload' : 'Characteristic Groups';
            },
        },
        {
            headerName: '',
            colId: 'actions',
            suppressMenu: true,
            sortable: false,
            filter: false,
            floatingFilter: false,
            resizable: false,
            pinned: 'right',
            width: 150,
            cellRenderer: (params) => {
                const { data } = params;
                return (
                    <div className={`${baseClassName}__actions`}>
                        <div className={`${baseClassName}__actions-edit`}>
                            {data.isEditable && (
                                <span className={`${baseClassName}__actions-edit-icon`} onClick={() => handleEdit(data?.id)}/>
                            )}
                        </div>
                        {data.isEditable && <span className={`${baseClassName}__actions-delete-icon`} onClick={() => handleDelete(data?.id)} />}
                        <NavLink to={`/dashboard/ppg_report/${data?.id}`}>
                            <div className={classnames(`${baseClassName}__actions-view-icon`)}></div>
                        </NavLink>
                    </div>
                );
            },
        },
    ]);

    return (
        <div className={classnames(`${baseClassName}`)}>
            <section className={classnames(`${baseClassName}__filters-section`)}>
                <span className={classnames(`${baseClassName}__title`)}>Your Team's PPGs</span>
            </section>
            <ByzzerTable
                rowData={ppgs} // Row Data for Rows
                columnDefs={ppgColumnDefs} // Column Defs for Columns
                defaultColDef={defaultPPGsColDef} // Column Defs for Columns
                tableArea={'ppgs'}
                enableColumnHideShow={true}
                enableSaveLayout={true}
                enableSaveSort={true}
            />
        </div>
    );
});

export default PPGGrid;
