import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './InviteMembers.scss';
import { ByzzerButton, ByzzerSelect, ByzzerTextInput, ByzzerTipIcon, WizardActions, WizardContent, WizardHeader, WizardStep } from "@byzzer/ui-components";
import { Popover } from '@material-ui/core';
import { useTenantApi } from '@/hooks/useTenantApi';
import { validate as isValidEmail } from 'email-validator';
import { Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { DomainMismatchReasonCode, TeamMember, TeamRole, UserInvite } from '@/types/InvitationTypes';
import { useUser } from '@/contexts/UserContext';
import { openErrorModal } from '@/components/form/ByzzerModal';
import { openDomainMismatchWarning } from '@/components/TeamManager/DomainMismatchWarning';
import { confirm } from '@/components/form';
import { OnboardingStepProps } from "../../OnboardingWizard";
import { emailDomainFoundInDomainList } from "@/utils";

export const InviteMembers = forwardRef(({
    action,
    isLastStep,
    step,
    onNext,
    onSkip,
    setBusy,
    title,
    nextText,
    busy,
    usage,
    ...props
}: OnboardingStepProps, ref) => {
    const stepRef = useRef<any>();
    const baseClassName = 'byz-onboarding-invite-members';



    const { inviteUsers, getTeamMembers, getCompanyMultiTenancyForUserEmail } = useTenantApi();
    const { nsCompany, user, features } = useUser();
    const inviteTemplate = {
        firstName: '',
        lastName: '',
        email: '',
        role: '' as TeamRole,
        teamId: undefined,
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const [invitation, setInvitation] = useState<UserInvite>(inviteTemplate);
    const [inviteCount, setInviteCount] = useState(0);
    const [invitations, setInvitations] = useState<UserInvite[]>([]);
    const [maxUsers, setMaxUsers] = useState(0);
    const [canAdd, setCanAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [remainingInvitations, setRemainingInvitations] = useState(0);
    const [members, setMembers] = useState<TeamMember[]>([]);
    const [shouldSaveData, setShouldSaveData] = useState(false);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const minimumInputLength = 5;

    useEffect(() => {
        getUsage();
    }, []);

    useEffect(() => {
        if (usage) {
            setMaxUsers(usage?.users?.limit);
            setInviteCount(usage?.users?.limit - usage?.users?.used);
        }
    }, [usage, invitations]);

    useEffect(() => {
        setCanAdd(canAddUser());
    }, [invitation]);

    useEffect(() => {
        let tempInvitaion = invitations?.filter((item) => item?.role !== 'viewer');
        setRemainingInvitations(inviteCount - tempInvitaion?.length);
    }, [invitations, inviteCount]);

    useEffect(() => {
        ;(async () => {
            if (shouldSaveData && invitations?.length) {
                    setBusy?.(true);
                    if (await handleInviteUser()) {
                        onNext();
                    } else {
                        setBusy?.(false);
                    }
            }
            setShouldSaveData(false);
        })()
    }, [shouldSaveData]);

    const getUsage = async () => {
        try {
            setLoading(true);
            const [users] = await Promise.all([getTeamMembers()]);
            if (usage?.users) {
                setMaxUsers(usage?.users?.limit);
                setInviteCount(usage?.users?.limit - usage?.users?.used);
                setMembers(
                    users
                        .map((user) => ({
                            ...user,
                            // join first and last name if either exists
                            fullName:
                                user.firstName?.trim?.() || user.lastName?.trim()
                                    ? `${user.firstName} ${user.lastName}`.trim()
                                    : '',
                        }))
                        .sort((a, b) => a.fullName?.localeCompare(b.fullName))
                );
            }
        } finally {
            setLoading(false);
        }
    };

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const sendUserInvitation = async (
        invitees: UserInvite[],
        domainMismatchReasonCode: DomainMismatchReasonCode | undefined = undefined
    ) => {
        try {
            await inviteUsers(invitees, domainMismatchReasonCode);
            // onComplete?.('OnboardingInvitations');
        } catch (err: any) {
            await openErrorModal({
                title: 'Uh Oh',
                content: <p>Something unexpected happened while sending your invitation.</p>,
                errorId: err.id
            });
        }
    };

    const handleInviteUser = async () => {
        setBusy?.(true);
        let duplicateInvitees: UserInvite[] = [];
        let consultantInvitees: UserInvite[] = [];
        try {
            await Promise.all(
                invitations?.map(async (invitee) => {
                    const inviteeEmailLowercase = invitee?.email?.toLowerCase();

                    if (!emailDomainFoundInDomainList(inviteeEmailLowercase, nsCompany?.domains)) {
                        const { userFound, multiTenantEnabled } = await getCompanyMultiTenancyForUserEmail(inviteeEmailLowercase);

                        if (multiTenantEnabled) {
                            consultantInvitees.push(invitee);
                        } else if (userFound) {
                            duplicateInvitees.push(invitee);
                        }
                    }
                })
            );
        } catch (err: any) {
            await openErrorModal({
                title: 'Uh Oh',
                content: <p>Something unexpected happened while sending your invitation.</p>,
                errorId: err.id
            });
        } finally {
            let invitees = [...invitations];
            if (consultantInvitees?.length) {
                invitees = invitations?.filter(
                    (inv) => !consultantInvitees?.map((cons) => cons.email)?.includes(inv.email)
                );
            }
            if (duplicateInvitees?.length) {
                invitees = invitations?.filter(
                    (inv) => !duplicateInvitees?.map((dup) => dup.email)?.includes(inv.email)
                );
            }
            let consultantConfirmContinue: boolean = false;
            if (consultantInvitees?.length) {
                if (
                    await confirm({
                        title: `${
                            invitees?.length ? 'One or more invitees are ' : 'The invitee is a '
                        }Byzzer approved third party advisor(s).`,
                        content: `${
                            invitees?.length
                                ? 'To authorize their access please use the "Add a Third party" Option. Continue adding rest of the invitees?'
                                : ''
                        }`,
                        yesLabel: `${invitees?.length ? 'Continue' : 'OK'}`,
                        noLabel: 'Cancel',
                    })
                ) {
                    consultantConfirmContinue = true;
                } else {
                    return false; // user cancelled
                }
            }
            let duplicateConfirmContinue: boolean = false;
            if (duplicateInvitees?.length) {
                if (
                    await confirm({
                        title: `${invitees?.length ? 'One or more invitees ' : 'The invitee '}already exists`,
                        content: `${invitees?.length ? 'Continue adding rest of the invitees?' : ''}`,
                        yesLabel: `${invitees?.length ? 'Continue' : 'OK'}`,
                        noLabel: 'Cancel',
                    })
                ) {
                    duplicateConfirmContinue = true;
                } else {
                    return false; // user cancelled
                }
            }
            if ( // create variables for this logic
                invitees?.length &&
                (!(consultantInvitees?.length && duplicateInvitees?.length) ||
                    (consultantInvitees?.length && consultantConfirmContinue) ||
                    (duplicateInvitees?.length && duplicateConfirmContinue))
            ) {
                await sendUserInvitation(invitees);
            }
        }
        return true; // ok to proceed to next step/finish onboarding flow
    };

    const handleAddInvitationClick = async () => {
        setBusy?.(true);

        try {
            const { 
                userFound: inviteeUserFound
            } = await getCompanyMultiTenancyForUserEmail(invitation?.email);
    
            setBusy?.(false);
    
            const inviteeEmailIsWithinCurrentCompanyDomains = emailDomainFoundInDomainList(invitation.email, nsCompany?.domains);
    
            let domainMismatchReasonCode: DomainMismatchReasonCode;
    
            if (!inviteeEmailIsWithinCurrentCompanyDomains && !features?.allowDifferentDomains && !inviteeUserFound) {
    
                const domainMismatchReasonSelected: {
                    type: 'domainMismatch',
                    data: { domainMismatchReasonCode: DomainMismatchReasonCode },
                } = await openDomainMismatchWarning({ 
                    // @ts-ignore
                    invitees: [invitation], domainMismatchInvitees: [invitation], proceedLabel: 'Save'
                })
    
                domainMismatchReasonCode = domainMismatchReasonSelected?.data?.domainMismatchReasonCode;
            }
    
            for (const key in invitation) {
                // Input validation
                if (key !== 'teamId' && !invitation[key]) return;
            }
            // If Mail Already added
            // If Invite limit reached
            if (
                invitations.find((item) => item.email === invitation.email) ||
                // invitations.length >= inviteCount ||
                !emailRegex.test(invitation.email)
            ) {
                return;
            }
            setInvitations((currentInvites) => [
                ...currentInvites, 
                ...(domainMismatchReasonCode ? [{ ...invitation, domainMismatchReasonCode}] : [invitation])
            ]);
            setInvitation(inviteTemplate);
        } catch (error) {
            // tbd
            console.error('error with handleAddInvitationClick', {invitation});
        } finally {
            setBusy?.(false);
        }
    };

    const removeInvite = (index) => {
        setInvitations(invitations.filter((item, i) => i !== index));
    };

    const onChange = (data) => {
        setInvitation((state) => ({
            ...state,
            ...data,
        }));
    };

    const handleChange = ({name,value}: ByzzerChangeEvent<string>) => {
        onChange({
            [name!]: value,
        });
    };

    const handleSelection = ({value: role}: ByzzerChangeEvent<TeamRole>) => {
        setInvitation((state) => ({
            ...state,
            role,
        }));
    };

    function notBlank(value) {
        return Boolean(value?.trim());
    }

    const canAddUser = () => {
        return (
            notBlank(invitation?.role) &&
            notBlank(invitation?.firstName) &&
            notBlank(invitation?.lastName) &&
            isValidEmail(invitation?.email) &&
            !members?.map((mbr) => mbr?.email)?.includes(invitation?.email)
        );
    };

    const ErrorMessage = () => {
        let message: string = '';

        const email = invitation?.email;

        if (email.length <= minimumInputLength) {
        } else if (!isValidEmail(email)) {
            // not valid email
            message = ' - Email address is not valid.';
        } else if (members?.map((mbr) => mbr?.email)?.includes(email)) {
            // member or invite exists already
            message = ' - This email is associated with an existing user or invited user.';
        }
        return <span className={`${baseClassName}__error`}>{message}</span>;
    };

    const memberOptions = [
        { value: 'admin', display: 'Admin' },
        { value: 'user', display: 'User' },
        { value: 'viewer', display: 'Viewer' },
    ];

    const onlyViewerRole = [{ value: 'viewer', display: 'Viewer' }];

    const subTitle =
        inviteCount > 0 ? (
            <>
                Your subscription includes a total of {maxUsers} members. You have {remainingInvitations} invitation
                {remainingInvitations === 1 ? '' : 's'} remaining. Invited users will receive an email with a link to
                join.
            </>
        ) : (
            <></>
        );

    // this is required to allow multiple refs to the step.  needs the dependency array or will cause infinite loop
    useImperativeHandle(ref, () => stepRef.current, []);

    async function handleSkip() {
        setBusy?.(true);
        onSkip();
    };

    function handleNext(id?: string) {
        setShouldSaveData(true);
        return false;
    }

    return (
        <WizardStep className={classnames(baseClassName)} byzRef={stepRef} title={title} id={step}>
            <WizardHeader className={classnames({
                // 'report-run-config-wizard-header--valid': filtersValid
            })}>
                <h1 className={`report-run-config-wizard__step-title`}>
                    {title}
                </h1>
            </WizardHeader>
            <WizardContent>



                <div className={classnames(`${baseClassName}`)}>
                    {user?.role === 'admin' && (
                        <header className={`${baseClassName}__header`}>
                            {subTitle}
                        </header>
                    )}
                    <section className={`${baseClassName}__editor`}>
                        <ByzzerTextInput
                            className={`${baseClassName}__extended-input first-name`}
                            name={'firstName'}
                            value={invitation.firstName}
                            onChange={handleChange}
                            placeholder={'John'}
                            label={'First Name'}
                        />
                        <ByzzerTextInput
                            className={`${baseClassName}__extended-input last-name`}
                            name={'lastName'}
                            value={invitation.lastName}
                            onChange={handleChange}
                            placeholder={'Doe'}
                            label={'Last Name'}
                        />
                        <ByzzerTextInput
                            className={`${baseClassName}__extended-input`}
                            name={'email'}
                            value={invitation.email}
                            onChange={handleChange}
                            placeholder={'john.doe@company.com'}
                            label={
                                <span>
                                    Email Address <ErrorMessage />
                                </span>
                            }
                        />
                        <div className={`${baseClassName}__extended-input`}>
                            <ByzzerSelect
                                className={`${baseClassName}__extended-input member-type`}
                                options={remainingInvitations < 1 ? onlyViewerRole : memberOptions}
                                value={invitation.role}
                                name={'member-type'}
                                onChange={handleSelection}
                                placeholder={'Select from the list'}
                                label={
                                    <>
                                        Member Type
                                        <ByzzerTipIcon
                                            tip={
                                                <>
                                                    <div className={`${baseClassName}__user-type-info`}>
                                                        <p>The Member Type will set a team member’s permissions in the product.</p>
                                                        <br/>
                                                        <p>
                                                            <b>Admin:</b> can edit account information, view usage, run reports, and view reports run by
                                                            other team members
                                                        </p>
                                                        <p>
                                                            <b>User:</b> can run reports and view reports run by other team members
                                                        </p>
                                                        <p>
                                                            <b>Viewer:</b> can view reports run by other team members
                                                        </p>
                                                    </div>
                                                </>
                                            }
                                            tipDelay={75}
                                        />
                                    </>
                                }
                            />
                        </div>
                        <ByzzerButton className={`${baseClassName}__add-btn`} onClick={handleAddInvitationClick} disabled={!canAdd}>
                            Add Invitation
                        </ByzzerButton>
                    </section>
                    <section>
                        <h2 className={`${baseClassName}__title`}>Invited Members</h2>
                        <div className={`${baseClassName}__invitations`}>
                            {invitations.map((invitation, index) => (
                                <Tag
                                    key={invitation.email}
                                    className={`${baseClassName}__invitation`}
                                    icon={<CloseOutlined onClick={() => removeInvite(index)} />}
                                >
                                    <p key={index}>
                                        <span className={`${baseClassName}__invitation-role`}>[{invitation.role}]</span>{' '}
                                        {invitation.firstName} {invitation.lastName} | {invitation.email}
                                    </p>
                                </Tag>
                            ))}
                            {!invitations.length && (
                                <div className={`${baseClassName}__invitations-placeholder`}>
                                    You have not added any invitations
                                </div>
                            )}
                        </div>
                    </section>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div className={`${baseClassName}__user-type-info`}>
                            <h1>Member Types</h1>
                            <p>The Member Type will set a team member’s permissions in the product.</p>
                            <p>
                                <b>Admin:</b> can edit account information, view usage, run reports, and view reports run by
                                other team members
                            </p>
                            <p>
                                <b>User:</b> can run reports and view reports run by other team members
                            </p>
                            <p>
                                <b>Viewer:</b> can view reports run by other team members
                            </p>
                        </div>
                    </Popover>






                </div>
            </WizardContent>
            <WizardActions 
                // disableNext={!invitations?.length} 
                nextDisabledTip={'You must choose all required values to continue.'}
                nextText={nextText}
                beforeNext={handleNext}
            >
                <ByzzerButton
                    className={`${baseClassName}__skip-btn`}
                    type="negative"
                    onClick={handleSkip}
                    label={"Skip for now"}
                />
            </WizardActions>
        </WizardStep>
    );

});

export default InviteMembers;

InviteMembers.displayName = 'InviteMembers';

