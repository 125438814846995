import { useEffect, useState } from 'react';
import './StoriesLibrary.scss';
import { alert as byzzerAlert, openModal } from '@/components/form';
import {useTenantApi} from '@/hooks/useTenantApi';
import { storySelectorAttributes } from '@/utils/storySelectorUtil';
import { getDefaultImageSrc, useBetterNavigate } from '@/utils/utils';
import className from 'classnames';
import { TrackClick } from '@/analytics';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { initializeStoredValues, getAccessLevel } from '@/utils/storiesUtil';
import { useApp, useUser, useEvents } from '@/contexts/UserContext';
import { showErrorModal } from '@/utils';
import { storyToHide, alertMessages, eventTypes, storySkus } from '@/config/globalVars';
import { SubscriptionInfo } from "@/constants/subscription.constants";
import { useStoryRunService } from '@/services/storiesRun.service';
import { ByzzerCard } from '@/components/ByzzerCard';

const wrapperClass = className('stories-library');
// Needs Refactoring to be done in sprint 1.64
function StoryLibrary() {
    const { getMySubscriptionUsage } = useTenantApi();
    const navigate = useBetterNavigate();
    const events = useEvents();
    const { allProducts } = useApp();
    const { getStoryDefaultRunConfigBySku } = useStoryRunService();
    const { subscription, user, subscribedSkus, company } = useUser();
    const [loading, setLoading] = useState(false);
    const [stories, setStories] = useState([]);
    const imageBucketURL = import.meta.env.REACT_APP_IMAGES_BASE_URL;
    const [remainingCoreRuns, setRemainingCoreRuns] = useState();
    const userAccessLevel = parseInt(getAccessLevel(company?.accessLevel));
    //TODO: This variable is just a placeholder this variable will be replaced by storyConfig(albyConfig) attributes thubnail lable BYZ-7160
    const storySkusForTags = [storySkus.categoryManagement];
    const StoryType = "Core Advanced";
    useEffect(() => {
        loadStorySubscriptionUsage();
        initializeStoredValues();
    }, []);

    useEffect(() => {
        loadStorySubscription();
    }, [allProducts, subscription]);

    useEffect(() => {
        const [event] = events;
        if (event?.type === eventTypes.storyGenerated) {
            loadStorySubscriptionUsage();
        }
    }, [events]);

    const loadStorySubscription = async () => {
        if (allProducts) {
            const products = allProducts.filter(isValidStory);
            products.forEach((elem) => {
                if (!elem?.thumbnailUrl?.includes(imageBucketURL, 0)) {
                    elem.thumbnailUrl = imageBucketURL + elem?.thumbnailUrl;
                }
            });
            setStories(products);
        }
    };

    async function loadStorySubscriptionUsage(showLoadingIndicator = true) {
        try {
            if (showLoadingIndicator) setLoading(true);
            const usage = await getMySubscriptionUsage();
            setRemainingCoreRuns(usage?.basicReports ? usage.basicReports.limit - usage.basicReports.used : 0);
        } catch (err) {
            showErrorModal(err);
        } finally {
            setLoading(false);
        }
    }

    const isValidStory = (data) => {
        // In Progress
        if (storyToHide?.includes(data?.sku) && 2 <= userAccessLevel && userAccessLevel <= 4) {
            return false; // brand review story not to be shown to user
        } else
            return (
                data?.type === 'story' &&
                Number.isFinite(data?.subscriptionLevel) &&
                data?.sku &&
                data?.title !== '' &&
                data?.metadata?.env?.reportRunValue &&
                data?.metadata?.env?.reportSelectors &&
                data?.metadata?.env?.objective_lists &&
                data?.metadata?.env?.reportSelectors?.length !== 0 &&
                Object.keys(data?.metadata?.env?.objective_lists).length !== 0
            );
    };

    const validateStoryCountAndShow = async (storyConfig) => {
        if (user?.role === 'viewer') {
            openModal({
                closeOnClickOutside: true,
                title: "Sorry! You don't have access to do that.",
                content: (
                    <>
                        <p>As a viewer, you don't have access to run a new story. </p>
                        <p>Reach out to your account admin to upgrade your member type to user.</p>
                    </>
                ),
                className: `${wrapperClass}__viewer-access-modal`,
                headerType: 'normal',
            });
            return;
        }

        let subscribed = subscribedSkus.includes(storyConfig.sku);

        if (subscribed) {
            setLoading(true);

            let [usage] = await Promise.all([getMySubscriptionUsage()]);

            if (userAccessLevel === 5) {
                storyConfig.metadata.env.reportSelectors = storyConfig.metadata?.env?.reportSelectors.filter(
                    (e) => e !== 'market:option5'
                );
            }

            storyConfig.reportDetails = {
                reportType: storyConfig?.type,
                reportSelectors: storyConfig.metadata?.env?.reportSelectors
                    ? storySelectorAttributes(storyConfig?.metadata?.env?.reportSelectors)
                    : {},
                productSku: storyConfig?.sku,
                storyType: storyConfig.metadata?.env?.storyType,
                reportRunValue: storyConfig.metadata?.env?.reportRunValue,
            };

            if (storyConfig?.type === 'story' && usage) {
                if (usage?.basicReports?.limit === 0) {
                    setLoading(false);
                    byzzerAlert({
                        onResolve: () => { },
                        content: (
                            <>
                                <p>{alertMessages.noTeamRunsLeft}</p>
                            </>
                        ),
                    });
                } else if (usage?.basicReports?.limit - usage?.basicReports?.used <= 0) {
                    setLoading(false);
                    byzzerAlert({
                        onResolve: () => { },
                        content: (
                            <>
                                <p>{alertMessages.usedAllPurchasedCredits}</p>
                            </>
                        ),
                    });
                } else if (
                    usage?.basicReports?.limit - usage?.basicReports?.used - storyConfig.metadata?.env?.reportRunValue <
                    0
                ) {
                    setLoading(false);
                    byzzerAlert({
                        onResolve: () => { },
                        content: (
                            <>
                                <p>{alertMessages.upgradeSubscription}</p>
                            </>
                        ),
                    });
                }
                if (
                    usage?.basicReports?.limit - usage?.basicReports?.used > 0 &&
                    usage?.basicReports?.limit -
                    usage?.basicReports?.used -
                    storyConfig?.metadata?.env?.reportRunValue >=
                    0
                ) {
                    localStorage.setItem('storySku', storyConfig?.sku);
                    localStorage.setItem('dataSpecificationValues', {});
                    localStorage.removeItem('selectorsConfigurations');
                    localStorage.removeItem('selectedObjectives');

                    navigate('/dashboard/story_builder', {
                        params: {},
                        state: {
                            storyConfig: {
                                ...storyConfig,
                            }, //will need change later 10-19-20 to pass all data, not conditionally pass only some.
                            defaultValues: await getStoryDefaultRunConfigBySku(storyConfig?.sku),
                            remainingReportRuns: remainingCoreRuns,
                        },
                    });
                }
            }
            setLoading(false);
        }
    };


    function renderUsage() {
        return (
            <header className="my-stories__sub-heading">
                <div>
                    {!(subscription?.active) ? (
                        <p>
                            {SubscriptionInfo.SUBSCRITPION_EXPIRED}
                        </p>
                    ) : (
                        remainingCoreRuns === Infinity ? (
                            <div>
                                You have <span className="stories-report-count-text">unlimited core report runs</span> to
                                use for stories.
                            </div>
                        ) : (
                            <div>
                                You have{' '}
                                <span className="stories-report-count-text">
                                    {remainingCoreRuns > 0 ? remainingCoreRuns : 'no'} report run
                                    {remainingCoreRuns === 1 ? '' : 's'} left
                                </span>{' '}
                                in your subscription.{' '}
                            </div>
                        )
                    )}
                </div>
            </header>
        );
    }

    return (
        <>
            <ByzzerMask loading={loading} />

            {stories?.length > 0 && <>{renderUsage()}</>}

            {/* STORIES LIST VIEW   */}
            <div className="subscription-reports ad-hoc-reports--runnable subscription-story">
                <div className="subscription-reports__content">
                    <div className="story-groups">
                        <div className={'report-group'}>
                            <div className="report-group__cards">
                                {Boolean(stories.length) &&
                                    stories.map((item, index) => {
                                        return (
                                            <ByzzerCard
                                                key={index}
                                                shouldTrackClick={true}
                                                trackClickName={`My Reports Report (${item?.title}) clicked`}
                                                title={item?.title}
                                                onClick={() => validateStoryCountAndShow(item)}
                                                description={item?.description}
                                                badges={[
                                                    `Deducts ${item?.metadata?.env?.reportRunValue} Report ${item?.metadata?.env?.reportRunValue > 1 ? `Runs` : `Run`}`
                                                ]}
                                                isLocked={!subscribedSkus.includes(item?.sku)}
                                                thumbnailUrl={item?.thumbnailUrl}
                                                thumbnailImageSize={'lg'}
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {stories?.length === 0 && !loading && <div className={`${wrapperClass}__label`}>No Stories found.</div>}
        </>
    );
}

export default StoryLibrary;
