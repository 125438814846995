import {ByzzerInput, ByzzerSelect} from "@/components/form";
import React, {useEffect, useState} from "react";
import {create as createModal} from "react-modal-promise";
import {TipIcon} from "@/components/icons";
import LockIcon from "@/images/icons/LockIcon.svg"
import ByzzerModal2 from "@/components/modals/ByzzerModal2";
import {useTenantApi} from "@/hooks/useTenantApi";
import {openErrorModal} from "@/components/form/ByzzerModal";
import {openTeamRoleInfo} from "@/components/TeamManager/TeamRoleInfo";
import {openTeamInfo} from "@/components/TeamManager/TeamInfo";
import { ByzzerButton, ByzzerSelectOption } from "@byzzer/ui-components";
import { TeamMember, TeamRole } from "@/types/InvitationTypes";

const teamRoleOptions: ByzzerSelectOption[] = [
    {value: 'admin', display: 'Admin'},
    {value: 'user', display: 'User'},
    {value: 'viewer', display: 'Viewer'}
];

function MemberApprovalEditor({onResolve, teamOptions = [], member, ...props}: {
    member: TeamMember;
    teamOptions: ByzzerSelectOption[];
    onResolve: (result?: any) => void;
}) {
    const {approveUser, updateUser} = useTenantApi();

    const [showingErrorModal, setShowingErrorModal] = useState(false);
    const [busy, setBusy] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [loading, setLoading] = useState(false);
    const [approval, setApproval] = useState<{
        userId: number;
        role: TeamRole;
        teamId: number | null;
    }>({
        userId: member?.id,
        role: 'viewer',
        teamId: null
    });

    useEffect(() => {
        setApproval(state => ({
            ...state,
            userId: member?.id,
        }));
    }, [member])
    useEffect(() => {
        setCanSave(Boolean(approval?.teamId && approval.role));
    }, [approval])

    async function onTeamRoleInfo() {
        openTeamRoleInfo()
    }

    async function onTeamInfo() {
        const result = await openTeamInfo()
        if (result.function === "redirect") {
            onCloseClick();
        }
    }

    async function onApproveClick() {
        try {
            setBusy(true)
            await approveUser(approval);
            onResolve('updated');
        } catch (err: any) {
            setShowingErrorModal(true);

            switch (err.code) {
                case 'user_not_found':
                    await openErrorModal({
                        title: `User Can't Be Found`,
                        content: <>
                            <p>We were unable to find the team member you are trying to approve.</p>
                            <p>If you think this is incorrect please contact our support team.</p>
                        </>,
                        errorId: err.id
                    });
                    break;
                case 'insufficient_credits':
                    await openErrorModal({
                        title: 'Out Of User Credits',
                        content: <>
                            <p>You have used all of your "Admin" and "User" credits.</p>
                            <p>You have a few options.</p>
                            <ol>
                                <li>Purchase additional credits in the shop.</li>
                                <li>Change another team member to a "Viewer".</li>
                                <li>Make the current team member a "Viewer".</li>
                            </ol>
                        </>
                    });
                    break;
                case 'duplicate_approval':
                    await openErrorModal({
                        title: 'Already Approved',
                        content: <>
                            <p>{member.firstName} {member.lastName} has already been approved.</p>
                        </>
                    });
                    break;
                default:
                    await openErrorModal({
                        title: 'Uh Oh',
                        content: <>
                            <p>Something unexpected happened while we were updating the member.</p>
                        </>,
                        errorId: err.id
                    });
                    setShowingErrorModal(false);
            }
        } finally {
            setBusy(false);
        }
    }


    function setTeamRole(role) {
        setApproval(current => ({
            ...current,
            role
        }));
    }

    function setTeamId(teamId) {
        setApproval(current => ({
            ...current,
            teamId
        }));
    }

    function onCloseClick() {
        onResolve(false);
    }

    return (
        // @ts-ignore
        <ByzzerModal2 show={!showingErrorModal} className={'subscription-users__member-editor'}>
            {/* @ts-ignore */}
            <ByzzerModal2.Header className="subscription-users__modal-title" onClose={onCloseClick}>
                Approve Team Member
            </ByzzerModal2.Header>
            <ByzzerModal2.Content className="member-editor__content">
                {/* @ts-ignore */}
                <ByzzerModal2.Mask show={loading}>{loading.message}</ByzzerModal2.Mask>
                <p>Do you want to give {member?.firstName} {member?.lastName} access to Byzzer?</p>
                <p>If so, please choose a Member Type and Team for their account.</p>
                <div className="member-editor__form-group">
                    <h2>
                        {/* @ts-ignore */}
                        Member Type <TipIcon onClick={onTeamRoleInfo}></TipIcon>
                    </h2>
                    <ByzzerSelect
                        className="subscription-users__modal-add-item-input"
                        name={'role'}
                        onChange={setTeamRole}
                        value={approval.role}
                        placeholder={'Member Type'}
                        options={teamRoleOptions}
                        variant="secondary"/>
                </div>
                <div className="member-editor__form-group">
                    {/* @ts-ignore */}
                    <h2>Choose a Team <TipIcon onClick={onTeamInfo}/></h2>

                    <ByzzerSelect
                        className="subscription-users__modal-add-item-input"
                        readonly={true}
                        name={'team'}
                        onChange={setTeamId}
                        value={approval?.teamId}
                        placeholder={'Choose a Team'}
                        options={teamOptions}
                        variant="secondary"/>
                </div>
            </ByzzerModal2.Content>
            <ByzzerModal2.Footer className="subscription-users__modal-footer">

                <ByzzerButton type="negative" onClick={onCloseClick}>No, Cancel</ByzzerButton>
                <ByzzerButton
                    disabled={!canSave || busy}
                    onClick={onApproveClick}
                >
                    {busy ? 'Loading...' : 'Yes, Approve'}
                </ByzzerButton>
            </ByzzerModal2.Footer>
        </ByzzerModal2>
    );
}
// @ts-ignore
export const openMemberApprovalEditor = createModal(MemberApprovalEditor)