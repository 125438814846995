import React, { useRef } from 'react';
import { openTermsAndConditions } from '@/components/modals/TermsAndConditions';
import { useUser } from '@/contexts/UserContext';
import 'firebase/compat/firestore';
import { useBetterNavigate } from '@/utils';
import classnames from 'classnames';
import { ByzzerMenu } from '@/components/ByzzerMenu';
import { useModals } from '@/components/form/ByzzerModal';
import { openProductTours } from '@/components/modals/ProductTours';
import { Notification } from '@/pages/HomePage/Notification';
import '../dashboard/DashboardHeader.scss';
import { HomePageNotifications } from '../MyHomePage';
import { useAccountService } from '@/hooks/useAccountService';

const baseClassName = 'dashboard-header';

type FreeUserDashboardHeaderProps = {
    disabled?: boolean;
    className?: string;
}

export function FreeUserDashboardHeader({ disabled = false, className }: FreeUserDashboardHeaderProps) {
    const { user, company, isCsrObo, subscription, activeNotifications, preSales, isMultiTenant } = useUser();
    const navigate = useBetterNavigate();
    const { confirm } = useModals();
    const accountRef = useRef<HTMLDivElement>(null);
    const itemRef = useRef<HTMLDivElement>(null);
    const helpRef = useRef<HTMLDivElement>(null);
    const notificationsRef = useRef<HTMLDivElement>(null);
    const { logout } = useAccountService();

    const subscriptionInactive = !subscription?.active;

    const handleLogout = async () => {
        await logout();
        if (isCsrObo) {
            navigate('/auth/csr_sign_in');
        } else {
            navigate('/auth/sign_in');
        }
    };

    return (
        <>
            <div className={classnames(baseClassName, className)}>
                {isMultiTenant && (
                    <div className={classnames(`${baseClassName}__tenant`)}>
                        <div className={classnames(
                            `${baseClassName}__tenant-user-role`,
                            `${baseClassName}__tenant-user-role--${user?.role}`, 
                            { // todo: remove for production
                                [`${baseClassName}__tenant-user-role-ab`]: localStorage['tenant-order'] === 'role-company',
                                [`${baseClassName}__tenant-user-role-ba`]: localStorage['tenant-order'] === 'company-role',
                            }
                        )}>{user?.role}</div>
                        <div className={classnames(
                            `${baseClassName}__tenant-company-name`, 
                            { // todo: remove for production
                                [`${baseClassName}__tenant-company-name-ab`]: localStorage['tenant-order'] === 'company-role',
                                [`${baseClassName}__tenant-company-name-ba`]: localStorage['tenant-order'] === 'role-company'
                            }
                        )}>{company?.displayName}</div>
                    </div>
                )}
                <div
                    className={classnames(`${baseClassName}__nav-item`, `${baseClassName}__nav-item--notifications`)}
                    ref={notificationsRef}
                >
                    {activeNotifications?.length > 0 && (
                        <span className={`${baseClassName}__nav-item--notifications-badge`}>
                            {activeNotifications?.length < 99 ? activeNotifications.length || 0 : '99+'}
                        </span>
                    )}
                </div>
               { preSales && <div
                    ref={itemRef}
                    className={classnames(`${baseClassName}__nav-item`, `${baseClassName}__nav-item--my-items`)}
                >
                    My Items
                </div>}
                <div
                    ref={accountRef}
                    className={classnames(`${baseClassName}__nav-item`, `${baseClassName}__nav-item--account`)}
                >
                    {user?.firstName}
                </div>
                <div
                    ref={helpRef}
                    className={classnames(`${baseClassName}__nav-item`, `${baseClassName}__nav-item--help`)}
                />
            </div>
            <ByzzerMenu
                className={`${baseClassName}__menu`}
                reference={itemRef}
                offset={[0, 2]}
                triggerTarget={itemRef.current}
                items={[
                    {
                        // to: '/dashboard/characteristic_explorer/client',
                        to: '/dashboard/static_report/characteristic-explorer-for-client',
                        iconType: 'char-explorer',
                        content: 'Characteristic Explorer',
                        disabled: true,
                    },
                    {
                        // @ts-ignore
                        showIf: import.meta.env.REACT_APP_SHOW_CHARACTERISTICS_EXPLORER_INTERNAL === 'true',
                        // to: '/dashboard/characteristic_explorer/niq',
                        to: '/dashboard/static_report/characteristic-explorer-for-niq',
                        iconType: 'char-explorer',
                        content: 'Characteristic Explorer - Internal',
                        disabled: true,
                    },
                    {
                        to: '/dashboard/custom_characteristics',
                        iconType: 'custom-chars',
                        content: 'Custom Characteristics',
                        disabled: true,
                    },
                    {
                        to: '/dashboard/static_report/item_coding_explorer',
                        iconType: 'item-explorer',
                        content: 'Item Coding Explorer',
                        disabled: true,
                    },
                    {
                        to: '/dashboard/my_portfolio',
                        iconType: 'portfolio',
                        content: 'Portfolio',
                        disabled: true,
                    },
                    {
                        to: '/dashboard/ppgs',
                        iconType: 'ppgs',
                        content: 'PPGs',
                        disabled: true,
                    },
                    {
                        to: '/dashboard/product_coding_request',
                        iconType: 'product-coding',
                        content: 'Product Coding Form',
                        disabled: true,
                    },

                    {
                        to: '/dashboard/product_lookup',
                        iconType: 'product-lookup',
                        content: 'Product Lookup',
                        disabled: true,
                    },
                    {
                        // @ts-ignore
                        showIf: import.meta.env.REACT_APP_SHOW_NIQ_SYND_HIERARCHY_LOOKUP === 'true',
                        to: '/dashboard/my_items/synd_hierarchy_lookup',
                        iconType: 'product-lookup',
                        content: 'NIQ Synd Hier Lookup',
                        disabled: true,
                    },
                ]}
            />
            <ByzzerMenu
                className={`${baseClassName}__menu`}
                reference={accountRef}
                triggerTarget={accountRef.current}
                offset={[0, 2]}
                header={
                    <header className={'account-menu__header'}>
                        <div className={'account-menu__name'}>
                            {user?.firstName} {user?.lastName}
                        </div>
                        <div className={'account-menu__company'}>{company?.displayName}</div>
                    </header>
                }
                items={[
                    {
                        content: 'Subscription',
                        to: '/dashboard/my_subscription/subscription',
                    },
                    {
                        content: 'Profile',
                        to: '/dashboard/user_profile/preferences',
                    },
                    {
                        content: 'Legal',
                        onClick() {
                            openTermsAndConditions();
                        },
                    },
                    ...(isMultiTenant ? [{
                        content: 'Switch Company',
                        to: '/dashboard/switch_company'
                    }]: []),
                    {
                        content: 'Logout',
                        onClick: handleLogout,
                    },
                ]}
            />
            <ByzzerMenu
                className={`${baseClassName}__menu`}
                reference={helpRef}
                offset={[0, 2]}
                triggerTarget={helpRef.current}
                items={[
                    {
                        onClick(type) {
                            // todo: add popup blocker detection
                            window.open('https://learn.byzzer.ai', '_resources');
                        },
                        content: 'Resource Library',
                        iconType: 'resource-library',
                    },
                    {
                        to: '/dashboard/prior_approved',
                        content: 'Prior Approval Markets',
                        iconType: 'prior-approval-market',
                    },
                    {
                        onClick(type) {
                            openProductTours();
                        },
                        content: 'Product Tours',
                        iconType: 'product-tours',
                    },
                    {
                        to: '/dashboard/market_maps/retailer',
                        content: 'Market Maps',
                        iconType: 'market-maps',
                    },
                ]}
            />
            <ByzzerMenu
                className={`${baseClassName}__menu`}
                reference={notificationsRef}
                triggerTarget={notificationsRef.current}
                items={[]}
                children={<HomePageNotifications className={`${baseClassName}__notifications`} componentView="header" />}
            />
        </>
    );
}

export default FreeUserDashboardHeader;
