import { FocusKPI } from "./ApiTypes";

export type TimePeriodType = 'relative' | 'week_ending';

export type chartDataConfigurationType = 'Weeks' | 'Months' | 'Quarters' | undefined
// this allows us to keep the type in sync with the array values
// https://stackoverflow.com/a/59857409/3006294
// todo: replace with something like 1w, 4w, 13w, 52w, ytd because they're parsable and i18nable
export const RELATIVE_TIME_PERIODS = ['Latest 1 Week', 'Latest 4 Weeks', 'Latest 13 Weeks', 'Latest 52 Weeks', 'Year to Date'] as const;
export const OMNI_TIME_PERIODS = ['Latest Month', 'Latest Year', 'Year-to-Date'] as const;
export type RelativePeriod = typeof RELATIVE_TIME_PERIODS[number] | typeof OMNI_TIME_PERIODS[number];

export type RelativeTimePeriod = {
    type: 'relative' | 'omni';    
    period?: RelativePeriod;
};

export type WeekEndingTimePeriod = {
    type: 'week_ending';
    period?: {
        weeks?: number;
        endDate?: Date;
        type?:string;
    };
    
    chartDataConfig?: string;
}

export type MonthEndingTimePeriod = {
    type: 'omni_custom';
    period?: {
        months?: number;
        endDate?: Date ;
    };
}

export type CustomTimePeriod = WeekEndingTimePeriod | MonthEndingTimePeriod;
export type TimePeriod = CustomTimePeriod | RelativeTimePeriod ;

export type CharacteristicCondition = {
    characteristic: string | null;
    characteristicDisplayValue: string | null;
    condition: 'is' | 'is not';
    value: string[]
    isCustom?: boolean;
}

export type DemographicCondition = {
    demographic: string;
    condition: 'is' | 'is not';
    value: string[];
    data: any;
}

export type DimensionCondition = {
    dimension: string;
    condition: 'is' | 'is not';
    value: string[]
}

export type RunConfigMarket = {
    path: string;
    key?: string;
    name: string;
    selectionType?: string;
    masterCompany?: string;
    marketGroup?: string;
    isRemaining?: boolean;
    remainingMarketRunConfig?: any;
    marketHasPartialApproval?: boolean; //needed to enable/disable stackBy chkbox in layout tab
    subMarketType?: string;
    disabledMarketNode?:boolean;
    isParentCompany?: boolean;
    isOutletOfParentCompany?: boolean;
};


export type ComparisonMarket = {
    channel: string;
    marketLevel: string;
    marketType: string;
    labelInfoCode: string;
};

export type ProductSelection = {
    focusBrands: string[];
    categories: string[];
}

export type LegacyProductSelection = {
    brand: string[] | string;
    category: string[];
}

export type ChannelType = 'account' | 'major_market' | 'region' | 'stateline';
export type Channel = {
    type: ChannelType;
    channel?: string;// fixed values from story https://adlm.nielseniq.com/jira/browse/BYZ-448
    level?: number;
}

export type ReportAggregationLevel = 'total_store' | 'department' | 'super_category' | 'category';
export type ReportDatatype = 'rms' | 'cps' | 'omni';
export type ReportDimension = 'products' | 'markets';

export type CharacteristicDimension = {
    characteristicsCode: string;
    characteristic: string;
    isCustom?: boolean;
};

export type DemographicDimension = {
    display: string;
    value: string;
};

export type ReportRunConfig = { // this should be the totality of 'include[XYZ]' options in Alby
    datatype: ReportDatatype;
    reportDimension: ReportDimension;
    demographics?: DemographicCondition[]; // will be renamed ; will become array 
    demographicDimensions?: (string | DemographicDimension)[];  // will be renamed 
    timePeriod: TimePeriod;
    chartDataConfigurationType?: chartDataConfigurationType;
    markets?: RunConfigMarket[];
    comparisonMarket?: ComparisonMarket;
    /**
     * This controls which product hierarchy level is used when selecting categories, but is not used for report generation.
     */
    categorySelectionAggregationLevel?: ReportAggregationLevel;
    categories?: string[]; // ex: maps to includeCategories.  ideally, all are just prefixes of include and then the reportrunconfig item name
    subcategories?: string[];
    aggregationLevel?: ReportAggregationLevel;
    brands?: string[];
    omniFocusProducts?: string[];
    omniProductLevel?: string;
    subMarkets?: RunConfigMarket[];
    productDimensions?: CharacteristicDimension[];
    characteristics?: CharacteristicCondition[];
    focusBrands?: string[];
    themeSku?: string;
    growthThresholds?: string[];
    salesThresholds?: string[];
    themeAttribute?: string[];
    ppgId?: number;    
    attributeGroup?: string[];
    attributes?: string[];
    focusKPI?: FocusKPI;
    parentCompany?:string[];
    attributesCustomCodes?: string[];
    metadata?: Record<string, any>;
}

export type LegacyAggregationHierarchy = 'Total Store' | 'Department' | 'Super Category' | 'Category';

export type LegacyCharacteristic = {
    chr_display_name: string;
    chr_code: string;
}

export type LegacyCharacteristicFilter = {
    characteristic: string;
    condition: 'is' | 'is not';
    value: string[];
};
export type LegacyTimePeriod = { // Select Time Period accordion
    selectionType: 'pre-defined' | 'custom'; // OR 'custom'
    timePeriod?: string; // populated for pre-defined with a string representing the time period
    weeks?: number; // populated for custom
    endDate?: string; // '2020-10-31' populatd for custom
};
export type LegacyReportConfig = {
    dimension: 'products' | 'markets', // OR 'markets' (Select Comparison Type accordion, may not be always there)
    focusProductSelections: { // Select Focu Product accordion
        brand?: string[];
        focusBrand?: string[]; // Single value list for options 1/1A/1B(optional), blank/not sent for options 2/3/4/5, multiple values for option5
        competitiveProductSet: { // Available in all options(1 to 5)
            categories?: string[];
            subcategories?: string[];
        },
        characteristicDimension?: string[]; // Empty array/not sent for options 1/2/5, single value list for option3, multi value list for option4
        characteristicFilters?: LegacyCharacteristicFilter[]; // Empty array/with values for options 1&2, not sent for 3/4/5
        customSegmentIds?: string[];
        themeSku?: string;
        themeAttribute?: string[];
        salesThreshold?: string;
        growthThreshold?: string;
        aggregationHierarchy?: LegacyAggregationHierarchy;
    };
    focusMarketSelections: { // Select Focus Market accordion
        focusMarket: {
            byMarketName?: { // For options 1/2/3/4
                marketGroup: 'rms' | 'cps';
                markets: string[];
            },
            byMarketType?: { // For Option5
                marketType?: string;
                channel?: string;
                marketLevel: string;
            }
        }
    };
    timePeriodSelections: LegacyTimePeriod;
};
