import './DodTimePeriodFilterBuilder.scss';
import React, {ReactNode, useEffect, useMemo, useRef, useState} from 'react';
import classnames from 'classnames';
import {DodFilter, DodFilters, SummedSelection, TimePeriodValue} from '@/types/DodRun';
import {ByzzerChangeEventHandler} from '@byzzer/ui-components';
import {DodTimePeriodFilterTypePicker} from './DodTimePeriodFilterTypePicker';
import {DodTimePeriodFilterValuePicker} from './DodTimePeriodFilterValuePicker';
import {DodTimePeriodFilterPreview} from './DodTimePeriodFilterPreview';
import {DodFilterType, DodTimePeriodFilterType} from '@/components/DodConfigEditor/types';
import {DodSummedSelectionEditor} from '@/components/DodConfigEditor/common';
import {DodPanel, DodPanelRef} from "@/components/DodConfigEditor/common/DodPanel";
import {useEventDataWithUserInfo, useTrackEvent} from "@/analytics/AnalyticsContext";
import {DodPreset} from "@/types/ApiTypes";
import { useUser } from '@/contexts/UserContext';
import DodCustomTimePeriod
    from "@/components/DodConfigEditor/builders/DodTimePeriodFilterBuilder/DodCustomTimePeriod/DodCustomTimePeriod";
import {useAutoUpdateRowColConfigs} from "@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext";

const baseClassName = 'dod-time-period-filter-builder';

export type DodTimePeriodFilterBuilderProps = {
    className?: string;
    name?: string;
    tip?: ReactNode;
    value: DodFilters;
    onChange: ByzzerChangeEventHandler<DodFilters>;
    onValidityChange?(e: ByzzerValidityChangeEvent): void;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const prefixToFilterType: Record<string, DodTimePeriodFilterType> = {
    'pe': "latestDates",
    '1w': 'weeks',
    '4w': '4weeks',
    '1m': 'months',
    '1q': 'quarters',
    '1y': 'years',
    'c': 'custom',
    '4s': '4StaticWeeks',
    'pp': 'previousPeriods'

}

export function DodTimePeriodFilterBuilder({
                                               className,
                                               name,
                                               value,
                                               onChange,
                                               onValidityChange,
                                               tip,
                                               ...props
                                           }: DodTimePeriodFilterBuilderProps) {

    const filters = value;

    const {subscription} = useUser()
    const isFreeUser = subscription?.metadata?.isFree ?? false
    const selectionLimit = subscription?.metadata?.extractLimits?.timePeriods ?? Infinity

    const trackEvent = useTrackEvent();
    const getEventData = useEventDataWithUserInfo();
    const [filterType, setFilterType] = useState<DodTimePeriodFilterType>('latestDates');
    const [valueSelectionMode, setValueSelectionMode] = useState<'single' | 'summed'>('single');
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [filterText, setFilterText] = useState<string>('');
    const [summedSelection, setSummedSelection] = useState<SummedSelection<TimePeriodValue[]> | undefined>();
    const [summedSelectionIndex, setSummedSelectionIndex] = useState<number | undefined>();
    const singleActions = useMemo<ActionConfig[]>((): ActionConfig[] => {
        const excludeSummedSelection = ['latestDates', 'previousPeriods'].includes(filterType);
        return [
            {
                icon: 'summed',
                type: 'summed',
                tip: 'Create A Summed Selection',
                action() {
                    setValueSelectionMode('summed');
                },
                include: !isFreeUser && !excludeSummedSelection,
                disabledTip: "Summing these periods can lead to duplication. Please sum from a different time periods folder instead."
            },
        ];
    }, [filterType]);

    const valuePanelRef = useRef<DodPanelRef>(null);

    useEffect(() => {
        onValidityChange?.({
            name,
            isValid: Boolean(value.timePeriods.values.length + value.timePeriods.summedSelections.length),
        });
    }, [value.timePeriods.values, value.timePeriods.summedSelections]);

    function handleFilterTypeChange(e) {
        if (e.value === 'custom') {
            trackEvent({
                type: 'click',
                name: 'dod_filter_type_click',
                data: getEventData({ dodWizardStep: 'time_period', panel: 'filter selection', filterType: e.value }),
            });
        }
        setFilterType(e.value);
    }

    function handleSummedSelectionChange(e: ByzzerChangeEvent<SummedSelection<TimePeriodValue[]>>) {

        setSummedSelection(e.value);
        const updatedValue = value.timePeriods;
        if (summedSelectionIndex === undefined) {
            updatedValue.summedSelections = [...updatedValue.summedSelections, e.value] as any;
        } else {
            updatedValue.summedSelections = updatedValue.summedSelections.map((v, i) => i === summedSelectionIndex ? e.value : v);
        }
        onChange({
            name,
            value: {
                ...value,
                timePeriods: updatedValue,
            },
        });
        setSummedSelectionIndex(undefined);
        setSummedSelection(undefined);
        setValueSelectionMode('single');
    }

    function reset() {

        valuePanelRef.current?.resetFilter();
        setValueSelectionMode('single');
        setSummedSelection(undefined);
        setSummedSelectionIndex(undefined);
    }

    function handleFilterValueChange(e: ByzzerChangeEvent<string[]>) {
        setSelectedValues(e.value);
        const updatedValue: DodFilter<TimePeriodValue[]> = value.timePeriods ?? {
            summedSelections: [],
        };
        updatedValue.values = e.value;
        onChange({
            name,
            value: {
                ...value,
                timePeriods: updatedValue,
            },
        });
    }

    function handleCustomTimePeriodChange(timePeriods: string[]) {
        const updatedValue: DodFilter<TimePeriodValue[]> = value.timePeriods ?? {
            values: [],
            summedSelections: [],
        };
        if(updatedValue.values.flat().length >= selectionLimit){
            updatedValue.values = [...updatedValue.values]
        } else{
            updatedValue.values = [...updatedValue.values, timePeriods];
        }
               onChange({
            name,
            value: {
                ...value,
                timePeriods: updatedValue,
            },
        });
    }

    function handleFilterChange(e: ByzzerChangeEvent<DodFilters>) {
        onChange({
            name,
            value: e.value,
        });
    }

    function handlePresetSelect(e: ByzzerChangeEvent<DodPreset>) {

        onChange({
            name,
            value: {
                ...value,
                timePeriods: e.value.values.timePeriods!
            }
        });
    }

    function handleEditSummedSelection(_: SummedSelection, index: number) {
        const summedSelection = value.timePeriods.summedSelections[index];
        const filterType = prefixToFilterType[summedSelection.values[0].slice(0, 2) as string];
        setValueSelectionMode('summed');
        setFilterType(filterType);
        setSummedSelection(summedSelection);
        setSummedSelectionIndex(index);
    }

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <DodTimePeriodFilterTypePicker
                value={filterType}
                onChange={handleFilterTypeChange}
                onPresetSelect={handlePresetSelect}
                filters={value}
                showPresetWarning={Boolean(value.timePeriods.values.length + value.timePeriods.summedSelections.length)}
            />

            <DodPanel name={'dod-time-period-picker'}
                      byzRef={valuePanelRef}
                      enableFilter={filterType !== 'custom'}
                      expandable={true}
                      onFilterChange={setFilterText}
                      trackClick={{ data: { dodWizardStep: "time_period", panel: "value picker" } }}
            >
                {valueSelectionMode === 'single' &&
                    (filterType === 'custom' ? (
                        <DodCustomTimePeriod
                        limit = {value.timePeriods.values  ? (selectionLimit - value.timePeriods.values.flat().length) : selectionLimit}
                        onApply={handleCustomTimePeriodChange}
                        />
                    ) : (
                        <DodTimePeriodFilterValuePicker
                            key={filterType}
                            limit={selectionLimit}
                            allowedSelectionLimit = {selectionLimit}
                            filters={value}
                            filterType={filterType}
                            filterText={filterText}
                            value={value.timePeriods.values}
                            actions={singleActions}
                            includeActions={true}
                            onApply={handleFilterValueChange}
                        />
                    ))}
                {valueSelectionMode === 'summed' && (
                    <DodSummedSelectionEditor<TimePeriodValue[]>
                        key={`${filterType}:${summedSelectionIndex}`}
                        onApply={handleSummedSelectionChange}
                        onCancel={reset}
                        includeActions={true}
                        value={summedSelection}
                        dodWizardStep='time_period'
                        filterType={filterType}
                    >
                        {({onChange, values, className}) => (
                            <DodTimePeriodFilterValuePicker
                                key={`${filterType}`}
                                filters={filters}
                                filterType={filterType}
                                value={values}
                                onChange={onChange}
                            />
                        )}
                    </DodSummedSelectionEditor>
                )}
            </DodPanel>
            <DodTimePeriodFilterPreview
                onChange={handleFilterChange}
                value={value}
                onEditSummedSelection={handleEditSummedSelection}
            />
        </div>
    );
}

export default DodTimePeriodFilterBuilder;
