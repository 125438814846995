import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import axios from 'axios';
import { message } from 'antd';
import {useTenantApi} from "@/hooks/useTenantApi";
import { useUser } from '@/contexts/UserContext';
import { useEffect } from 'react';
import {toMaxDataDates} from "@/utils/toMaxDataDates";
import {defaultTenantApiClient, normalizeHttpError, useApi} from "@/api";
import { CsrAccessRoles } from '@/types/CsrOboTypes';

const unauthenticatedHttp = axios.create({
    // @ts-ignore
    baseURL: import.meta.env.REACT_APP_BASEURL,
});

const authenticatedHttp = axios.create({
    // @ts-ignore
    baseURL: import.meta.env.REACT_APP_BASEURL,
});

authenticatedHttp.interceptors.request.use(async (config) => {
    if (firebase.auth().currentUser) {
        // @ts-ignore
        config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken()}`;
    } else {
        console.warn('no auth user available')
    }
    return config;
});

export const useAccountService = () => {

    const {
        clearApiOboUser: clearTenantApiOboUser, 
        setApiOboUser: setTenantApiOboUser 
    } = useTenantApi();
    const {clearApiOboUser, setApiOboUser} = useApi();

    const { tenantId } = useUser();

    useEffect(() => {
        setAccountServiceHeader(tenantId);
    }, [tenantId]);

    function setAccountServiceHeader(id) {
        authenticatedHttp.defaults.headers['x-tenant-id'] = id;
    }

    async function getUser(forceRefresh = false) {
        if (firebase.auth()?.currentUser) {
            try {
                const { data } = await authenticatedHttp.get('/me/user', {
                    headers: {
                        Authorization: `Bearer ${await firebase.auth().currentUser!.getIdToken(forceRefresh)}`,
                    },
                });
                return data;
            } catch (err: any) {
                normalizeHttpError(err);
            }
        }
    }

    async function getAccount(forceRefresh = false) {
        if (firebase.auth()?.currentUser) {
            try {

                const {data} = await authenticatedHttp.get('/me', {
                    headers: {
                        Authorization: `Bearer ${await firebase.auth().currentUser!.getIdToken(forceRefresh)}`,
                    },
                });

                return {
                    ...data,
                    maxDataDates: toMaxDataDates(data.maxDataDates),
                };
            } catch (err: any) {
               normalizeHttpError(err);
            }
        }
    }

    /**
     * This is meant to be used in {@link setOboUserInRequestHeaders}
     * @param {string} email the email id of user to be logged in as
     * @param {string} role the role name of CSR Obo
     */
    function setAccountOboUser(email, role) {
        authenticatedHttp.defaults.headers['X-byzzer-obo'] = email;
        authenticatedHttp.defaults.headers['X-byzzer-obo-role'] = role;
    }

    /**
     * This is meant to be used in {@link clearOboUserFromRequestHeaders}
     */
    function clearAccountOboUser() {
        delete authenticatedHttp.defaults.headers['X-byzzer-obo'];
        delete authenticatedHttp.defaults.headers['X-byzzer-obo-role'];
    }

    function handleLoginError(err: any) {
        if (err.code === 'auth/user-not-found') {
            throw {
                status: 404,
                code: 'user_not_found',
            };
        } else if (err.code === 'auth/wrong-password') {
            throw {
                status: 403,
                code: 'wrong_password',
            };
        } else if (err.code === 'auth/user-disabled') {
            throw {
                status: 403,
                code: 'user_disabled',
            };
        } else {
            normalizeHttpError(err);
        }
    }

    async function login({ email, password }: { email: string, password: string }) {
        try {
            const lowerCaseEmailId = email.toLowerCase();
            const res = await firebase.auth().signInWithEmailAndPassword(lowerCaseEmailId, password);
            // Check Valid User Doc Object is available
            return res.user ? true : false;
        } catch (err: any) {
            handleLoginError(err);
        }
    }

    async function signup(requestBody) {
        try {
            const res = await unauthenticatedHttp.post(`/users/`, requestBody);
            return res && res.data ? res.data : {};
        } catch (err: any) {
            throw err;
        }
    }

    async function logout() {
        localStorage.removeItem('tenantId');
        clearOboUserInfo();
        await firebase.auth().signOut();
    }

    async function updatePassword({ oldPass, newPass }) {
        if (firebase.auth().currentUser!.email) {
            try {
                const cred = firebase.auth.EmailAuthProvider.credential(firebase.auth().currentUser?.email!, oldPass);
                await firebase.auth().currentUser!.reauthenticateWithCredential(cred);
                await firebase.auth().currentUser!.updatePassword(newPass);
                message.success('Password changed successfully');
            } catch (err: any) {
                message.error(err?.message || err?.error?.message || 'Something went wrong');
            }
        }
    }


    /**
     * Get the byzzer user email, as firebase auth knows it.
     * @param email
     * @return An email, if it exists in FB auth, or undefined if not.
     */
    async function getByzzerUserEmail(email) {
        const res = await authenticatedHttp.get(`/csrobo/user/${email}`);
        return res.data;
    }

    type OboInfo = {
        isObo: boolean;
        csrAccessRoles: CsrAccessRoles[]
    }
    /**
     * @param {string | undefined} email csr email to check for
     * @returns {Promise<OboInfo>} If the user currently logged in is CSR or not and the csr access roles if any
     */
    async function getIsCsrObo(email = undefined): Promise<OboInfo> {
        const {data} = await authenticatedHttp.get(`/csrobo/iscsrobo/${email || ''}`);
        return data;
    }

    /**
     * @param {string} email the email id of user to be logged in as
     * @param {string} role the role name of CSR Obo
     */
    function setLocalOboUser(email, role) {
        localStorage.setItem('byzzerobo', email);
        localStorage.setItem('byzzeroboRole', role);
    }

    function setOboUserInRequestHeaders() {
        // fetching obo details from local storage
        const email = localStorage.getItem('byzzerobo');
        const role = localStorage.getItem('byzzeroboRole');

        if (!email || !role) {
            return;
        }

        // setting the obo details in headers of other services
        setAccountOboUser(email, role);
        setTenantApiOboUser(email, role);
        defaultTenantApiClient.setApiOboUser(email, role);
        setApiOboUser(email, role);

        // setting the obo details in header of obo service
        unauthenticatedHttp.defaults.headers['X-byzzer-obo'] = email;
        unauthenticatedHttp.defaults.headers['X-byzzer-obo-role'] = role;
    }
    /**
     * This can be applied when there is no csr user logged in
     */
    function clearOboUserFromRequestHeaders() {
        // clearing the obo details from headers of other services
        clearAccountOboUser();
        clearTenantApiOboUser();
        defaultTenantApiClient.clearApiOboUser();
        clearApiOboUser();

        // clearing the obo details from header of obo service
        delete unauthenticatedHttp.defaults.headers['X-byzzer-obo'];
        delete unauthenticatedHttp.defaults.headers['X-byzzer-obo-role'];
    }

    /**
     * This is meant to be used in {@link clearOboUserInfo}
     */
    function clearLocalOboUser() {
        // clearing obo details from local storage
        localStorage.removeItem('byzzerobo');
        localStorage.removeItem('byzzeroboRole');
    }

    /**
     * This should be applied when we are sure that obo user has logged out
     * Be careful of this because it can effect the obo session in other tab of browser
     */
    function clearOboUserInfo() {
        // this function can be used to reset obo details
        clearLocalOboUser();
        clearOboUserFromRequestHeaders();
    }

    return {
        getUser,
        getAccount,
        setAccountOboUser,
        clearAccountOboUser,
        login,
        signup,
        logout,
        updatePassword,
        getByzzerUserEmail,
        getIsCsrObo,
        setLocalOboUser,
        setOboUserInRequestHeaders,
        clearOboUserFromRequestHeaders,
        clearOboUserInfo,
        setAccountServiceHeader
    }

}
