import './DodProductFilterValuePicker.scss';
import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import {ByzzerChangeEventHandler} from '@byzzer/ui-components';
import {useUser} from '@/contexts/UserContext';
import {
    alertSelectionLimitExceed,
    filterHasExplicitValues,
    filterHasValues,
    filterValuesToStrings,
} from '@/components/DodConfigEditor/common/utils';
import {DodFilterType, DodProductFilterType} from '@/components/DodConfigEditor/types';
import {useCategoryService} from '@/services/category.service';
import {
    DodFilterValuePicker,
    DodFilterValuePickerRef,
    DodValueOption,
} from '@/components/DodConfigEditor/common/DodFilterValuePicker';
import {alert} from '@/components/form';
import { useDodWizard } from '@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext';
import useAllExceptProductFields from '@/utils/useAllExceptProductFields';
import {isAsyncFunction} from '@/utils';
import {useTenantApi} from '@/hooks';
import {DodFilters} from '@/types/DodRun';


const baseClassName = 'dod-product-filter-value-picker';

export type DodProductFilterValuePickerProps = {
    name?: string;
    className?: string;
    filterType: DodFilterType<DodProductFilterType>;
    filters: DodFilters;
    value?: string[] | 'all';
    onChange?: ByzzerChangeEventHandler<string[]>;
    onApply?: ByzzerChangeEventHandler<string[]>;
    actions?: ActionConfig[];
    pickerRef?: React.Ref<DodFilterValuePickerRef>;
    includeActions?: boolean;
    filterText?: string;
    limit?: number;
    refreshState?: number;
    isSummedSelection?: boolean;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

type FilterValueConfig = {
    getOptions(values: DodFilters, data: any): Promise<DodValueOption[]> | DodValueOption[];
    valueToStrings?(value: any): string[] | 'all';
    stringsToValue?(strings: string[] | 'all'): any;
    filterValues?(searchTerm: string, filterType?: string | number | undefined): Promise<DodValueOption[]> | DodValueOption[];
};

function defaultValueToStrings(value: any) {
    return value;
}

function defaultStringsToValue(strings: string[] | 'all'): string[] | 'all' {
    return strings;
}

export function DodProductFilterValuePicker({
    name,
    className,
    filterType,
    filters,
    value,
    onChange,
    onApply,
    limit,
    ...props
}: DodProductFilterValuePickerProps) {
    const {
        findBrands,
        findManufacturer,
        findParentCompany,
        findUpcDescriptions,
        getCharacteristicValuesForCategories,
        getCustomCharWithNASegment,
        getUpcDescriptionSearch,
        getManufacturerSearch,
        getBrandsSearch,
        getParentCompanySearch,
        getPPGById
    } = useTenantApi();
    const { categories: userCategories } = useUser();
    const {allExceptSelectionFields} = useDodWizard();
    const {
        getSuperCategoriesForDepartments,
        getCategoriesForDepartments,
        getSubCategoriesForDepartments,
        getCategoriesForSuperCategories,
        getSubCategoriesForSuperCategories,
        getSubCategoriesForCategories,
        getSuperCategoriesForCategories,
        getDepartsForCategories,
        getCategoriesBasedOnFilter,
    } = useCategoryService();
    const {
        categories: allExceptProductFieldsCategories,
        superCategories: allExceptProductFieldsSuperCategories,
        subCategories: allExceptProductFieldsSubCategories,
    } = useAllExceptProductFields(filters, allExceptSelectionFields);
    const [options, setOptions] = useState<DodValueOption[]>([]);
    const getFilterKey = (field: string) => allExceptSelectionFields.includes(field) ? `exclude_${field}` : field;
    const parentCompanyKey = getFilterKey('parentCompanies');
    const manufacturerKey = getFilterKey('manufacturers');
    const brandKey = getFilterKey('brands');
    const upcKey = getFilterKey('upcs');
    const filterValueConfigs = useMemo<Record<DodProductFilterType, FilterValueConfig>>(
        () => ({
            categories: {
                getOptions(filters) {
                    let categories: string[] = [];
                    let superCategories = filterValuesToStrings(filters.superCategories);
                    if(allExceptSelectionFields.includes('superCategories')) {
                        superCategories = allExceptProductFieldsSuperCategories;
                    }
                    if (filterHasExplicitValues(filters.superCategories)) {
                        categories = getCategoriesForSuperCategories(superCategories).filter((category) =>
                            userCategories?.includes(category)
                        );
                    } else if (filterHasExplicitValues(filters.departments)) {
                        categories = getCategoriesForDepartments(
                            filterValuesToStrings(filters.departments)
                        ).filter((category) => userCategories?.includes(category));
                    } else {
                        categories = userCategories;
                    }
                    return categories.map((value) => ({
                        text: value,
                        value,
                    }));
                },
            },
            subcategories: {
                getOptions(filters) {
                    let subcategories: string[] = [];
                    let categories = filterValuesToStrings(filters.categories, userCategories);
                    if(allExceptSelectionFields.includes('categories')) {
                        categories = allExceptProductFieldsCategories;
                    }
                    if (filterHasExplicitValues(filters.categories)) {
                        subcategories = getSubCategoriesForCategories(categories);
                    } else if (filterHasExplicitValues(filters.superCategories)) {
                        subcategories = getSubCategoriesForSuperCategories(
                            filterValuesToStrings(filters.superCategories), true
                        );
                    } else if (filterHasExplicitValues(filters.departments)) {
                        subcategories = getSubCategoriesForDepartments(filterValuesToStrings(filters.departments), true);
                    } else {
                        subcategories = getSubCategoriesForCategories(userCategories);
                    }
                    return subcategories.map((value) => ({
                        text: value,
                        value,
                    }));
                },
            },
            superCategories: {
                getOptions(filters) {
                    let superCategories: string[] = [];
                    let departments = filterValuesToStrings(filters.departments);
                    if(allExceptSelectionFields.includes('departments')) {
                        departments = getDepartsForCategories(userCategories).filter(department => !filterValuesToStrings(filters.departments).includes(department));
                    }
                    const userSuperCategories = getSuperCategoriesForCategories(userCategories);
                    if (filterHasExplicitValues(filters.departments)) {
                        superCategories =
                            getSuperCategoriesForDepartments(departments)?.filter((item) =>
                                userSuperCategories.includes(item)
                            ) || [];
                    } else {
                        superCategories = userSuperCategories;
                    }
                    return superCategories.map((value) => ({
                        text: value,
                        value,
                    }));
                },
            },
            departments: {
                getOptions() {
                    return getDepartsForCategories(userCategories).map((value) => ({
                        text: value,
                        value,
                    }));
                },
            },
            upcs: {
                async getOptions(filter) {
                    const upcs = await findUpcDescriptions({
                        categories: allExceptProductFieldsCategories,
                        subcategories: allExceptProductFieldsSubCategories,
                        [parentCompanyKey]: filterValuesToStrings(filters.parentCompanies),
                        [manufacturerKey]: filterValuesToStrings(filters.manufacturers),
                        [brandKey]: filterValuesToStrings(filters.brands),
                    });
                    return upcs.map(({ upc, description }) => ({
                        text: `${upc} ${description}`,
                        value: `${upc} ${description}`,
                    }));
                },
                async filterValues(searchTerm) {
                    setLoading(true);
                    try {
                        const upcs = await getUpcDescriptionSearch({
                            maxResults: 10000,
                            categories: allExceptProductFieldsCategories,
                            subcategories: allExceptProductFieldsSubCategories,
                            [parentCompanyKey]: filterValuesToStrings(filters.parentCompanies),
                            [manufacturerKey]: filterValuesToStrings(filters.manufacturers),
                            [brandKey]: filterValuesToStrings(filters.brands),
                            ...(searchTerm ? { searchTerm } : {}),
                        });
                        return upcs.results.map(({ upc, description }) => ({
                            text: `${upc} ${description}`,
                            value: `${upc} ${description}`,
                        }));
                    } catch (e) {
                        return [] as DodValueOption[];
                    } finally {
                        setLoading(false);
                    }
                },
            },
            brands: {
                async getOptions() {
                    const brandsData = await findBrands({
                        categories: allExceptProductFieldsCategories,
                        subcategories: allExceptProductFieldsSubCategories,
                        [parentCompanyKey]: filterValuesToStrings(filters.parentCompanies),
                        [manufacturerKey]: filterValuesToStrings(filters.manufacturers),
                        [upcKey]: filterValuesToStrings(filters.upcs).map(upc => upc.substring(0, upc.indexOf(" "))),
                        descriptions: filterHasValues(filters.upcs)
                            ? true
                            : !filterHasExplicitValues(filters.productDescriptions)
                            ? []
                            : filterValuesToStrings(filters.productDescriptions).map(description => description.substring(description.indexOf(" ") + 1, description.length)),
                    });
                    return brandsData.map((brand) => ({
                        text: brand,
                        value: brand,
                    }));
                },
                async filterValues(searchTerm) {
                    setLoading(true);
                    try {
                        const brandsData = await getBrandsSearch({
                            maxResults: 10000,
                            categories: allExceptProductFieldsCategories,
                            subcategories: allExceptProductFieldsSubCategories,
                            [parentCompanyKey]: filterValuesToStrings(filters.parentCompanies),
                            [manufacturerKey]: filterValuesToStrings(filters.manufacturers),
                            [upcKey]: filterValuesToStrings(filters.upcs).map(upc => upc.substring(0, upc.indexOf(" "))),
                            descriptions: filterHasValues(filters.upcs)
                            ? true
                            : !filterHasExplicitValues(filters.productDescriptions)
                            ? []
                            : filterValuesToStrings(filters.productDescriptions).map(description => description.substring(description.indexOf(" ") + 1, description.length)),
                            ...(searchTerm ? { searchTerm } : {}),
                        });
                        return brandsData.results.map((brand) => ({
                            text: brand,
                            value: brand,
                        }));
                    } catch (e) {
                        return [];
                    } finally {
                        setLoading(false);
                    }
                },
            },
            manufacturers: {
                async getOptions() {
                    const manufacturerData = await findManufacturer({
                        categories: allExceptProductFieldsCategories,
                        subcategories: allExceptProductFieldsSubCategories,
                        [parentCompanyKey]: filterValuesToStrings(filters.parentCompanies),
                        [manufacturerKey]: filterValuesToStrings(filters.manufacturers),
                        [brandKey]: filterValuesToStrings(filters.brands),
                        [upcKey]: filterValuesToStrings(filters.upcs).map(upc => upc.substring(0, upc.indexOf(" "))),
                        descriptions: filterHasValues(filters.upcs)
                            ? true
                            : !filterHasExplicitValues(filters.productDescriptions)
                            ? []
                            : filterValuesToStrings(filters.productDescriptions).map(description => description.substring(description.indexOf(" ") + 1, description.length)),
                    });
                    return manufacturerData.map((manufacturer) => ({
                        text: manufacturer,
                        value: manufacturer,
                    }));
                },
                async filterValues(searchTerm) {
                    setLoading(true);
                    try {
                        const manufacturerData = await getManufacturerSearch({
                            maxResults: 10000,
                            departments: filterValuesToStrings(filters.departments),
                            superCategories: filterValuesToStrings(filters.superCategories),
                            categories: allExceptProductFieldsCategories,
                            subcategories: allExceptProductFieldsSubCategories,
                            [parentCompanyKey]: filterValuesToStrings(filters.parentCompanies),
                            [upcKey]: filterValuesToStrings(filters.upcs).map(upc => upc.substring(0, upc.indexOf(" "))),
                            descriptions: filterHasValues(filters.upcs)
                            ? true
                            : !filterHasExplicitValues(filters.productDescriptions)
                            ? []
                            : filterValuesToStrings(filters.productDescriptions).map(description => description.substring(description.indexOf(" ") + 1, description.length)),
                            [brandKey]: filterValuesToStrings(filters.brands),
                            ...(searchTerm ? { searchTerm } : {}),
                        });
                        return manufacturerData.results.map((manufacturer) => ({
                            text: manufacturer,
                            value: manufacturer,
                        }));
                    } catch (e) {
                        return [] as DodValueOption[];
                    } finally {
                        setLoading(false);
                    }
                },
            },
            parentCompanies: {
                async getOptions() {
                    const parentCompanyData = await findParentCompany({
                        categories: allExceptProductFieldsCategories,
                        subcategories: allExceptProductFieldsSubCategories,
                        [parentCompanyKey]: filterValuesToStrings(filters.parentCompanies),
                        [manufacturerKey]: filterValuesToStrings(filters.manufacturers),
                        [brandKey]: filterValuesToStrings(filters.brands),
                        [upcKey]: filterValuesToStrings(filters.upcs).map(upc => upc.substring(0, upc.indexOf(" "))),
                        descriptions: filterHasValues(filters.upcs)
                            ? true
                            : !filterHasExplicitValues(filters.productDescriptions)
                            ? []
                            : filterValuesToStrings(filters.productDescriptions).map(description => description.substring(description.indexOf(" ") + 1, description.length)),
                    });
                    return parentCompanyData.map((parentCompany) => ({
                        text: parentCompany,
                        value: parentCompany,
                    }));
                },
                async filterValues(searchTerm) {
                    setLoading(true);
                    try {
                        const upcs = await getParentCompanySearch({
                            maxResults: 10000,
                            departments: filterValuesToStrings(filters.departments),
                            superCategories: filterValuesToStrings(filters.superCategories),
                            categories: allExceptProductFieldsCategories,
                            subcategories: allExceptProductFieldsSubCategories,
                            [upcKey]: filterValuesToStrings(filters.upcs).map(upc => upc.substring(0, upc.indexOf(" "))),
                            descriptions: filterHasValues(filters.upcs)
                                ? true
                                : !filterHasExplicitValues(filters.productDescriptions)
                                ? []
                                : filterValuesToStrings(filters.productDescriptions).map(description => description.substring(description.indexOf(" ") + 1, description.length)),
                            [manufacturerKey]: filterValuesToStrings(filters.manufacturers),
                            [brandKey]: filterValuesToStrings(filters.brands),
                            ...(searchTerm ? { searchTerm } : {}),
                        });
                        return upcs.results.map((parentCompany) => ({
                            text: parentCompany,
                            value: parentCompany,
                        }));
                    } catch (e) {
                        return [] as DodValueOption[];
                    } finally {
                        setLoading(false);
                    }
                },
            },
            productDescriptions: {
                async getOptions() {
                    const upcs = await findUpcDescriptions({
                        categories: allExceptProductFieldsCategories,
                        subcategories: allExceptProductFieldsSubCategories,
                        [parentCompanyKey]: filterValuesToStrings(filters.parentCompanies),
                        [manufacturerKey]: filterValuesToStrings(filters.manufacturers),
                        [brandKey]: filterValuesToStrings(filters.brands),
                    });
                    return upcs.map(({upc, description}) => ({
                        text: `${upc} ${description}`,
                        value: `${upc} ${description}`,
                    }));
                },
                async filterValues(searchTerm) {
                    setLoading(true);
                    try {
                        const upcs = await getUpcDescriptionSearch({
                            maxResults: 10000,
                            categories: allExceptProductFieldsCategories,
                            subcategories: allExceptProductFieldsSubCategories,
                            [parentCompanyKey]: filterValuesToStrings(filters.parentCompanies),
                            [manufacturerKey]: filterValuesToStrings(filters.manufacturers),
                            [brandKey]: filterValuesToStrings(filters.brands),
                            ...(searchTerm ? { searchTerm } : {}),
                        });
                        return upcs.results.map(({ upc, description }) => ({
                            text: `${upc} ${description}`,
                            value: `${upc} ${description}`,
                        }));
                    } catch (e) {
                        return [] as DodValueOption[];
                    } finally {
                        setLoading(false);
                    }
                },
            },
            characteristics: {
                async getOptions(_, { id }) {
                    const characteristics = await getCharacteristicValuesForCategories(allExceptProductFieldsCategories, id);
                    return characteristics.map((value) => ({
                        text: value,
                        value,
                    }));
                },
            },
            customCharacteristics: {
                async getOptions(_, { id }) {
                    let customCharsValueOptions = await getCustomCharWithNASegment(id);
                    return customCharsValueOptions.map((customChar) => ({
                        text: customChar,
                        value: customChar,
                    }));
                },
            },
            ppgs: {
                async getOptions(_, { id }) {
                    let ppgValueOptions = await getPPGById(id, undefined ,allExceptProductFieldsCategories);
                    return ppgValueOptions?.groups.map((ppgGroup) => ({
                        text: ppgGroup.groupName,
                        value: ppgGroup.groupName,
                    })) ?? [];
                },
                async filterValues(searchTerm, ppgId) {
                    setLoading(true);
                    try {
                        const ppgValueOptions = await getPPGById(ppgId as number, searchTerm === '' ? undefined : searchTerm, allExceptProductFieldsCategories);
                        return ppgValueOptions?.groups.map((ppgGroup) => ({
                            text: ppgGroup.groupName,
                            value: ppgGroup.groupName,
                        })) ?? [];
                    } catch (e) {
                        return [] as DodValueOption[];
                    } finally {
                        setLoading(false);
                    }
                },
            },
        }),
        [filters, allExceptSelectionFields, filterType]
    );
    const [normalizedValue, setNormalizedValue] = useState<string[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [optionsLoading, setOptionsLoading] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            const { getOptions } = filterValueConfigs[filterType.type];
            setOptionsLoading(isAsyncFunction(getOptions));
            const options = await getOptions(filters, filterType.data);
            setOptions(options ?? []);
            setOptionsLoading(false);
        })();
    }, [filterType.type, filterType.data]);

    useEffect(() => {
        const { valueToStrings = defaultValueToStrings } = filterValueConfigs[filterType.type];
        setNormalizedValue(valueToStrings(value));
    }, [value, filterType, filters]);

    function handleChange(e) {
        const { stringsToValue = defaultStringsToValue } = filterValueConfigs[filterType.type];
        onChange?.({
            ...e,
            value: stringsToValue(e.value),
        });
    }

    async function handleApply(e) {
        const { stringsToValue = defaultStringsToValue } = filterValueConfigs[filterType.type];

        if (filterType.type === 'customCharacteristics' && stringsToValue(e.value)?.length) {
            alert({
                title: 'FYI',
                content: <>
                    <p>Including Custom Characteristics will slow down the Data Point counter.</p>
                    <p>If it's calculating for longer than usual, that's why.</p>
                </>
            })
        }

        onApply?.({
            ...e,
            value: stringsToValue(e.value),
        });
    }
    const getLimitExceedFilterTypeName = useMemo(() => {
        switch (filterType.type) {
            case 'categories':
                return 'category(s)';

            case 'subcategories':
                return 'sub-category(s)';

            case 'departments':
                return 'department(s)';

            case 'superCategories':
                return 'super-category(s)';

            default:
                return 'category(s)';
        }
    }, [filterType]);

    return (
        <DodFilterValuePicker
            filterType={
                ['characteristics', 'customCharacteristics', 'ppgs'].includes(filterType.type)
                    ? filterType.data.id
                    : filterType.type
            }
            className={classnames(baseClassName, className)}
            options={options}
            limit={limit}
            loading={loading || optionsLoading}
            onLimitExceed={() => alertSelectionLimitExceed(getLimitExceedFilterTypeName, limit)}
            onChange={handleChange}
            onApply={handleApply}
            value={normalizedValue}
            onFilterChange={filterValueConfigs[filterType.type].filterValues}
            dodWizardStep="product"
            enableAllExcept={true}
            {...props}
        />
    );
}

export default DodProductFilterValuePicker;